define('pinpoint-manager/components/validated-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      if (this.get('serverValidation')) {
        this.$("input[id='" + this.get('cid') + "']").on('keyup', Ember.run.later(this, this.serverValidationDelayed));
      }
    },


    labelIsActive: Ember.computed('value', 'placeholder', function () {
      return this.get('value') || this.get('placeholder');
    }),

    inputClasses: Ember.computed('errors', 'showError', function () {
      if (!Ember.isEmpty(this.get('errors')) && this.get('showError')) {
        return 'invalid';
      }
    }),

    serverValidationDelayed: function serverValidationDelayed() {
      var _this = this;

      if (!Ember.isEmpty(this.get('value'))) {
        if (this.get('timeout')) {
          clearTimeout(this.get('timeout'));
        }
        var timeout = setTimeout(function () {
          _this.sendAction('serverValidation');
        }, 500);

        this.set('timeout', timeout);
      }
    },


    actions: {
      serverValidation: function serverValidation() {
        this.sendAction('serverValidation');
      },
      showErrors: function showErrors() {
        if (this.get('serverValidation')) {
          this.sendAction('serverValidation');
        }
        this.set('showError', true);
      }
    }
  });
});