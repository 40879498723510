define('pinpoint-manager/routes/registration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      if (params.mode === 'plans') {
        this.controllerFor('registration').set('activeStep', 'plans');
      } else if (params.mode === 'micro') {
        this.controllerFor('registration').set('chosenPlan', 'micro');
        this.controllerFor('registration').set('activeStep', 'user-info');
      }
    },


    // beforeModel() {
    //     var src, $meta = Ember.$('meta[name="stripe-sdk-url"]');
    //     if ($meta.length) {
    //       src = $meta.attr('content');
    //       $meta.remove();
    //         return new Ember.RSVP.Promise(function (resolve, reject) {
    //           Ember.$.getScript(src)
    //           .done(() => {
    //             resolve();
    //           })
    //           .fail(function (jqXhr) {
    //             reject(jqXhr);
    //           });
    //         });
    //     } else {
    //         return Ember.RSVP.resolve();
    //     }
    // },

    setupController: function setupController(controller) {
      controller.set('availablePlans', Ember.Object.create({
        'micro': Ember.Object.create({
          pricePerYearInEuroCents: 0,
          pricePerDeviceInEuroCents: 0,
          maxNumOfDevices: 1
        }),
        'small': Ember.Object.create({
          pricePerYearInEuroCents: 4999,
          pricePerYearInEuro: 49.99,
          pricePerDeviceInEuroCents: 500,
          pricePerDeviceInEuro: 5,
          // pricePerYearInEuroCents: 100,
          // pricePerYearInEuro: 1,
          // pricePerDeviceInEuroCents: 1,
          // pricePerDeviceInEuro: 0.1,
          maxNumOfDevices: 10
        }),
        'medium': Ember.Object.create({
          pricePerYearInEuroCents: 9999,
          pricePerYearInEuro: 99.99,
          pricePerDeviceInEuroCents: 300,
          pricePerDeviceInEuro: 3,
          maxNumOfDevices: 100
        })
      }));

      controller.set('hubspotPersonas', [{ 'text': 'Security Specialist, Compliance Manager', 'value': 'persona_1' }, { 'text': 'CISO, CIO, CTO, COO', 'value': 'persona_2' }, { 'text': 'Small/Medium Business Owner', 'value': 'persona_3' }, { 'text': 'CFO, CMO, Sales Director, Lawyer, PR', 'value': 'persona_4' }, { 'text': 'Large Business Owner, CEO, Chairman, Founder', 'value': 'persona_5' }]);

      // var stripeHandler = StripeCheckout.configure({
      //     key: config.StripeAPIKey,
      //     image: 'assets/images/logo-icon.png',
      //     token: function(token) {
      //         controller.send('register', token.id);
      //     },
      //     closed: function() {
      //         controller.set('checkoutErrors', true);
      //     }
      // });
      // controller.set('stripeHandler', stripeHandler);
    }
  });
});