define('pinpoint-manager/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    locationName: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    lat: _emberData.default.attr('number'),
    lng: _emberData.default.attr('number'),
    markerIndex: _emberData.default.attr('number'),
    devicesCount: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    address: _emberData.default.belongsTo('address', { async: false }),
    formattedAddress: Ember.computed('address', function () {
      var a = this.get('address');
      if (a && a.get('zipCode')) {
        return a.get('businessAddress') + ', ' + a.get('zipCode') + ' ' + a.get('city') + ', ' + a.get('country');
      } else if (a) {
        return a.get('businessAddress') + ', ' + a.get('city') + ', ' + a.get('country');
      }
    })
  });
});