define('pinpoint-manager/components/modals/add-edit-user', ['exports', 'pinpoint-manager/language', 'ember-service/inject'], function (exports, _language, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    store: (0, _inject.default)(),
    errorParser: (0, _inject.default)(),
    remodal: (0, _inject.default)(),

    user: null,
    mode: null,
    errors: null,
    isSelectingLocationEntity: false,
    locationEntityBeingEdited: null,
    notifyUser: true,

    roles: computed(function () {
      var roles = Ember.A();
      Ember.$.each(_language.default.roles, function (key, role) {
        if (role.available) roles.push(Ember.Object.create({ id: role.id, roleType: key, humanName: role.name, description: role.description }));
      });
      return roles;
    }),

    entities: computed(function () {
      return this.get('store').findAll('entity');
    }),

    locationEntitySelectorMode: computed('user.role', function () {
      if (this.get('user.isZoneManager')) {
        return "zoneSelection";
      } else if (this.get('user.isAreaManager')) {
        return "areaSelection";
      } else if (this.get('user.isLocationManager') || this.get('user.isAuditor')) {
        return "locationSelection";
      }
    }),

    isEditingCurrentUser: computed('user', 'currentUser', function () {
      return this.get('user.id') === this.get('currentUser.id');
    }),

    actions: {
      initializeUser: function initializeUser() {
        if (this.get('mode') === "add") {
          this.set('user', this.get('store').createRecord('user'));
        }
      },
      saveUser: function saveUser() {
        var _this = this;

        this.get('user').save({ adapterOptions: { notify: this.get('notifyUser') } }).then(function () {
          _this.setProperties({
            'completed': true,
            'errors': null
          });
        }).catch(function (response) {
          _this.set('errors', _this.get('errorParser').parseAndDisplay(response, 'box'));
        });
      },
      setUserEntity: function setUserEntity(selectedLocationEntity, locationEntityBeingEdited) {
        // setting a new locationEntity (no locationEntityBeingEdited was provided)
        if (locationEntityBeingEdited) {
          this.get('user').get('entities').removeObject(locationEntityBeingEdited);
        }
        this.get('user').get('entities').pushObject(selectedLocationEntity);
      },
      removeUserEntity: function removeUserEntity(entity) {
        this.get('user').get('entities').removeObject(entity);
      },
      removeAllUserLocationEntities: function removeAllUserLocationEntities() {
        this.set('user.entities', []);
      },
      setUserRole: function setUserRole(selectedRole) {
        this.set('user.roles', []);

        var role = void 0;
        role = this.get('store').peekRecord('role', selectedRole.id);
        if (!role) {
          role = this.get('store').createRecord('role', {
            id: '' + selectedRole.id,
            roleType: selectedRole.roleType
          });
        }
        this.get('user').get('roles').pushObject(role);
      },
      reset: function reset() {
        this.setProperties({
          'errors': null,
          'completed': false,
          'isSelectingLocationEntity': false,
          'locationEntityBeingEdited': null,
          'notifyUser': true
        });

        var user = this.get('user');

        user.rollbackAttributes();

        if (user && this.get('mode') !== 'add') {
          // force reload from server to rollback eventual changes to the user entities
          user.reload();
        }
      },
      closeModal: function closeModal() {
        this.get('remodal').close('add-edit-user');
      }
    }
  });
});