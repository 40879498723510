define('pinpoint-manager/serializers/photo', ['exports', 'pinpoint-manager/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // normalizeSingleResponse(store, type, payload) {
    //     var photos = payload.photos;

    //     var generatedId = 0;
    //     photos.forEach(function(photo) {
    //       photo.id = generatedId;
    //       generatedId++;
    //     });

    //     payload = { photos: photos };

    //     return this._super(store, type, payload);

    //   },

    //   normalizeArrayResponse(store, type, payload) {
    //       var photos = payload.photos;

    //       var generatedId = 0;
    //       photos.forEach(function(photo) {
    //         photo.id = generatedId;
    //         generatedId++;
    //       });

    //       payload = { photos: photos };

    //       return this._super(store, type, payload);
    //   }
  });
});