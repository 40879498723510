define('pinpoint-manager/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    isLoading: false,
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('isLoading', true);
        this.set('error', null);
        var credentials = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:cookie', credentials).then(function () {
          _this.set('error', null);
          _this.set('isLoading', false);
          //this.get('session').afterAuthentication();
        }, function (response) {
          _this.set('isLoading', false);
          _this.set('error', _this.get('errorParser').parseAndDisplay(response, 'box'));
        });
      }
    }
  });
});