define('pinpoint-manager/services/error-parser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    parseAndDisplay: function parseAndDisplay(response, target) {
      return this.display(this.parse(response), target);
    },
    parse: function parse(response) {
      var errors = [];
      try {
        if (response.payload && Ember.isArray(response.payload.errors)) {
          response.payload.errors.forEach(function (error) {
            // JSON API error format
            if (error.source && _typeof(error.source.pointer) !== undefined && error.source.pointer) {
              var pointer = error.source.pointer.substr(error.source.pointer.lastIndexOf('/') + 1);
              if (pointer.indexOf('.')) {
                pointer = pointer.substr(pointer.lastIndexOf('.') + 1);
              }
              pointer = Ember.String.capitalize(pointer).replace(/id/i, '').replace(/([a-z])([A-Z])/g, '$1 $2');
              errors.push('<b>Error</b>: ' + error.detail);
            } else if (error.detail) {
              if (error.detail.error) {
                errors.push('<b>Error</b>: ' + error.detail.error);
              } else {
                errors.push('<b>Error</b>: ' + error.detail);
              }
              // old format
            } else if (error.error) {
              errors.push('<b>Error</b>: ' + error.error);
            }
          });
        } else if (response.payload && response.payload.error) {
          errors.push('<b>Error</b>: ' + response.payload.error);
          // even older ofrmat
        } else if (response.result && response.result.errors) {
          errors.push(response.result.errors[0].error);
        } else if (response.error) {
          errors.push(response.error);
          // EmberError instances
        } else if (response.message) {
          errors.push(response.message);
        } else {
          Ember.Logger.debug(response);
          errors.push('Unable to perform action: unexpected error.');
        }
      } catch (e) {
        Ember.Logger.debug('Error parsing server error: ' + e);
        errors.push('Unable to perform action: unexpected error.');
      }
      return errors;
    },
    display: function display(errors, target) {
      switch (target) {
        case 'notification':
          errors.forEach(function (error, i, errors) {
            errors[i] = '<i class="material-icons left white-text text-darken1" style="font-size: 22px;">warning</i>' + error + '<br>';
          });
          Materialize.toast(errors, 4000);
          break;
        case 'box':
          return errors.join('<br>');
      }
    }
  });
});