define('pinpoint-manager/routes/user/verify', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    errorParser: (0, _inject.default)(),
    ajax: (0, _inject.default)(),

    model: function model(params) {
      var _this = this;

      if (Ember.isEmpty(params.token)) {
        this.controller.set('error', 'token provided. Please try again.');
      } else {
        this.get('ajax').post('pinpoint/users/verify/' + params.token, {
          contentType: 'application/json'
        }).then(function () {
          _this.controller.set('verified', true);
          Ember.run.later(_this, function () {
            _this.transitionTo('login');
          }, 4000);
        }).catch(function (response) {
          _this.controller.set('error', _this.get('errorParser').parseAndDisplay(response, 'box'));
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          error: null,
          verified: false
        });
      }
    }
  });
});