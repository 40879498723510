define('pinpoint-manager/routes/legal/terms-and-conditions', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject'], function (exports, _authenticatedRouteMixin, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    alerting: (0, _inject.default)(),
    errorParser: (0, _inject.default)(),

    actions: {
      acceptTc: function acceptTc() {
        var _this = this;

        var currentUser = this.get('currentUser');
        currentUser.set('tcAccepted', true);
        currentUser.get('content').save().then(function () {
          _this.transitionTo('devices');
          Ember.run.later(_this, function () {
            return _this.set('isLoading', false);
          }, 500);
        }).catch(function (response) {
          _this.set('isLoading', false);
          //currentUser.get('content').rollbackAttributes();
          _this.get('errorParser').parseAndDisplay(response, 'notification');
        });
      },
      willTransition: function willTransition(transition) {
        if (!this.get('currentUser.tcAccepted') && transition.targetName.indexOf('login') === -1) {
          transition.abort();
          this.get('alerting').notify('You must accept Terms & Conditions before using the application.', 'info');
        }
      }
    }
  });
});