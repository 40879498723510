define('pinpoint-manager/adapters/device-status', ['exports', 'pinpoint-manager/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: 'api/v1/pinpoint',
    queryUrlTemplate: '{+host}/api/v1/pinpoint/devices/{deviceId}/statuses',
    createRecordUrlTemplate: '{+host}/api/v1/pinpoint/devices/{deviceId}/status'
  });
});