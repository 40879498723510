define("pinpoint-manager/language", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Language = {
    "messages": {
      "unexpected_server_error": "Something unexpected happened. Please contact our support by clicking the \"Help\" button on the bottom."
    },
    "roles": {
      "ROLE_PINPOINT_ADMIN": { id: 9, "name": "Admin", "description": "Can create new Zones, Areas, Locations and administer users", available: true },
      "ROLE_PINPOINT_MANAGER_ZONE": { id: 12, "name": "Zone Manager", "description": "Can create Areas and Locations in his Zone", available: true },
      "ROLE_PINPOINT_MANAGER_AREA": { id: 7, "name": "Area Manager", "description": "Can create Locations in his Area", available: true },
      "ROLE_PINPOINT_MANAGER_LOCATION": { id: 11, "name": "Location Manager", "description": "Can create Devices in his Location", available: true },
      "ROLE_PINPOINT_AUDITOR": { id: 8, "name": "Auditor", "description": "Can audit Devices in his Location", available: true },

      "ROLE_MERCHANT_ADMIN": { "name": "Merchant Admin", "description": "" },
      "ROLE_MERCHANT_USER": { "name": "Merchant User", "description": "" },
      "ROLE_ACQUIRER_ADMIN": { "name": "MPA Admin", "description": "" },
      "ROLE_ACQUIRER_USER": { "name": "MPA", "description": "" },
      "ROLE_MERCHANT_ENTITY_ADMIN": { "name": "Merchant Admin", "description": "" },
      "ROLE_ACQUIRER_ENTITY_ADMIN": { "name": "MPA Admin", "description": "" },
      "ROLE_ADMIN": { "name": "ZeroRisk Admin", "description": "" }
    }
  };
  exports.default = Language;
});