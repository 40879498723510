define('pinpoint-manager/components/qr-code', ['exports', 'qrcode'], function (exports, _qrcode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'canvas',

    content: null,

    onError: function onError(error) {
      if (error) {
        throw new Ember.Error(error);
      }
    },

    didRender: function didRender() {
      var _getProperties = this.getProperties('element', 'content', 'onError'),
          element = _getProperties.element,
          content = _getProperties.content,
          onError = _getProperties.onError;

      if (!Ember.isNone(content)) {
        _qrcode.default.toCanvas(element, content, { scale: 10 }, onError);
      } else {
        Ember.warn('The content for QR code is missing.', { id: 'ember-simple-qrcode.empty-component-content' });
      }
    }
  });
});