define('pinpoint-manager/components/compliance-quickview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.get('store').find('compliance-report', this.get('device.id')).then(function (complianceReport) {
        _this.set('complianceReport', complianceReport);
      });
    }
  });
});