define('pinpoint-manager/routes/auth/login-url', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember-service/inject'], function (exports, _unauthenticatedRouteMixin, _inject) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
		session: (0, _inject.default)(),
		errorParser: (0, _inject.default)(),
		alerting: (0, _inject.default)(),

		beforeModel: function beforeModel(transition) {
			this._super.apply(this, arguments);
			if (this.get('session.isAuthenticated')) {
				this.get('alerting').notify('You are already logged in. Log out first if you wish to use another account.', 'info');
				return;
			}
			var controller = this.controllerFor('auth.login-url'),
			    token = transition.params['auth.login-url'].token,
			    qr = transition.queryParams.qr;

			if (!qr) {
				controller.authenticate(token);
			} else {
				controller.set('token', token);
			}
		}
	});
});