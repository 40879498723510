define("pinpoint-manager/controllers/not-found", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ["doggo"],
        doggo: false
    });
});