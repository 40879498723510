define('pinpoint-manager/serializers/device-model', ['exports', 'pinpoint-manager/serializers/application'], function (exports, _application) {
      'use strict';

      Object.defineProperty(exports, "__esModule", {
            value: true
      });
      exports.default = _application.default.extend({
            normalizeArrayResponse: function normalizeArrayResponse(store, type, payload) {
                  var deviceModels = payload.models;

                  var generatedId = 0;
                  deviceModels.forEach(function (deviceModel) {
                        deviceModel.id = generatedId;
                        generatedId++;
                  });

                  payload = { deviceModels: deviceModels };

                  return this._super(store, type, payload);
            }
      });
});