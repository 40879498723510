define('pinpoint-manager/controllers/devices/edit', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    errorParser: (0, _inject.default)(),
    isProcessing: false,
    errors: null,
    hasSelectedApprovedDevice: null,

    // isEnabledisDirty: Ember.computed('model.isEnabled', function() {
    //   return this.get('model').changedAttributes().isEnabled;
    // }),

    actions: {
      save: function save(model) {
        var _this = this;

        this.setProperties({
          'isProcessing': true,
          'errors': null
        });
        model.save().then(function () {
          Materialize.toast('Device successfully updated.', 2000);
          window.history.go(-1);
          _this.setProperties({
            'isProcessing': false,
            'errors': null
          });
        }).catch(function (response) {
          _this.get('errorParser').parseAndDisplay(response, 'notification');
        });
      },
      disable: function disable() {
        var _this2 = this;

        var status = this.store.createRecord('device-status', {
          device: this.get('model'),
          status: "DISMISSED",
          date: Date.now()
        });

        status.save().catch(function (response) {
          _this2.get('errorParser').parseAndDisplay(response, 'notification');
          status.unloadRecord();
        }).then(function () {
          _this2.get('model').reload();
        });
      },
      enable: function enable(model) {
        var _this3 = this;

        var status = this.store.createRecord('device-status', {
          device: model,
          status: "INSTALLED",
          date: Date.now()
        });

        status.save().catch(function (response) {
          _this3.get('errorParser').parseAndDisplay(response, 'notification');
          status.unloadRecord();
        }).then(function () {
          model.reload();
          _this3.store.query('device-status', { deviceId: model.get('id') });
        });
      },
      selectManufacturer: function selectManufacturer(selectedManufacturer) {
        var _this4 = this;

        var selectedManufacturerName = selectedManufacturer.get('manufacturer');
        this.get('model').set('manufacturer', selectedManufacturerName);
        this.set('hasSelectedApprovedDevice', selectedManufacturer.get('isApprovedManufacturer'));
        this.get('store').unloadAll('device-model');
        this.get('store').query('device-model', { manufacturer: selectedManufacturerName }).then(function (deviceModels) {
          _this4.set('availableDeviceModels', deviceModels);
        });
      },
      selectDeviceModel: function selectDeviceModel(selectedDeviceModel) {
        var device = this.get('model');

        device.set('model', selectedDeviceModel.get('model'));

        this.set('hasSelectedApprovedDevice', selectedDeviceModel.get('approvedDeviceId') ? true : false);

        if (this.get('availableDeviceModels')) {
          if (this.get('hasSelectedApprovedDevice')) {
            device.set('approvedDeviceId', selectedDeviceModel.get('approvedDeviceId'));
            device.set('customDeviceId', null);
          } else {
            device.set('customDeviceId', selectedDeviceModel.get('customDeviceId'));
            device.set('approvedDeviceId', null);
          }
        }
      },
      addNewCustomField: function addNewCustomField() {
        if (!this.get('model.customFields')) {
          this.set('model.customFields', []);
        }
        var customField = this.store.createFragment('custom-field', { name: 'Field Name', value: 'Value' });
        this.get('model.customFields').pushObject(customField);
      },
      removeCustomField: function removeCustomField(field) {
        this.get('model.customFields').removeObject(field);
        field.destroy();
      }
    }
  });
});