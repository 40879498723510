define('pinpoint-manager/routes/locations', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // model() {
    //   return this.store.findAll('entity'); //.then(entities => { return entities.getEach('contactInfo.address') });
    // },

    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);

      var src,
          $meta = Ember.$('meta[name="maps-sdk-url"]');
      if ($meta.length) {
        src = $meta.attr('content');
        $meta.remove();
        return new Ember.RSVP.Promise(function (resolve, reject) {
          window.__mapSDKloaded__ = Ember.run.bind(function () {
            window.__mapSDKloaded__ = null;
            resolve();
          });
          Ember.$.getScript(src + '&callback=__mapSDKloaded__').fail(function (jqXhr) {
            window.__mapSDKloaded__ = null;
            reject(jqXhr);
          });
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    afterModel: function afterModel() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.Logger.debug('Composing layout..');
        //$('.nav-wrapper').velocity({ width: '100%'});
        // $("#map, #controls").height( $(window).height() - $("nav").height() );
        // $("#map").width( $(window).width() );
        // $('.location-cards-list').perfectScrollbar();
        //$('.location-card-action-button').dropdown();
        // $(window).on('resize', Ember.run.bind(this, this.handleResize));

        //_this.contenttroller.send('createMap');
        //if (!this.controller.get('map')) {			
        _this.controller.set('map', new GMaps({
          div: '#map',
          lat: -12.043333,
          lng: -77.028333,
          zoom: 2,
          panControl: false,
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_RIGHT
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          },
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.DEFAULT,
            position: google.maps.ControlPosition.TOP_RIGHT
          }
        }));

        var input = /** @type {HTMLInputElement} */document.getElementById('pac-input');

        var searchBox = new google.maps.places.SearchBox(
        /** @type {HTMLInputElement} */input);

        google.maps.event.addListener(searchBox, 'places_changed', function () {
          var places = searchBox.getPlaces();

          Ember.Logger.debug('Place found:', places[0]);

          if (places.length === 0) {
            return;
          }

          // Remove any previous newLocationMarkers
          if (_this.controller.get('newLocationMarker')) {
            _this.controller.get('newLocationMarker').setMap(null);
          }

          var infowWindowContent = '<h5>New location</h5><h6>' + places[0].name + '</h6>';
          var newLocationMarker = _this.controller.get('map').createMarker({
            position: places[0].geometry.location,
            title: places[0].formatted_address,
            animation: google.maps.Animation.DROP,
            infoWindow: {
              content: infowWindowContent
            }
          });

          newLocationMarker.infoWindow.open(_this.controller.get('map').map, newLocationMarker);
          _this.controller.get('map').addMarker(newLocationMarker);
          _this.controller.set('newLocationMarker', newLocationMarker);
          Ember.Logger.debug('newLocationMarker', newLocationMarker);

          _this.controller.get('map').panBy(-$('#location-list').width(), 0);
          _this.controller.get('map').setCenter(places[0].geometry.location.lat(), places[0].geometry.location.lng());
          _this.controller.get('map').setZoom(4);

          // Parse places address components (short and long ones)
          var _address_components = places[0].address_components;
          var address_components = {},
              address_components_short = {};
          Ember.$.each(_address_components, function (k, v1) {
            Ember.$.each(v1.types, function (k2, v2) {
              address_components[v2] = v1.long_name;
            });
          });
          Ember.$.each(_address_components, function (k, v1) {
            Ember.$.each(v1.types, function (k2, v2) {
              address_components_short[v2] = v1.short_name;
            });
          });

          var newLocationEntityAddress = Ember.Object.create({
            businessAddress: address_components.street_number ? address_components.street_number + ' ' + address_components.route : address_components.route || address_components.premise || places[0].name,
            city: address_components.locality || address_components.administrative_area_level_3 || address_components.postal_town,
            stateProvince: address_components_short.administrative_area_level_1 || address_components_short.administrative_area_level_2,
            country: address_components.country,
            zipCode: address_components.postal_code,
            composedAddress: places[0].formatted_address,
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng()
          });
          _this.controller.set('newLocationEntityAddress', newLocationEntityAddress);
        });

        //}
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          isSearchingLocation: false,
          searchQuery: null,
          selectedZone: null,
          selectedArea: null,
          newLocationEntityAddress: null,
          newLocationEntity: null
        });
      }
    }
  });
});