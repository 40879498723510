define('pinpoint-manager/components/sub-navbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      if (this.$('.tabs')) {
        this.$('.tabs').tabs();
      }
    }
  });
});