define('pinpoint-manager/controllers/devices/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    remodal: Ember.inject.service(),

    isProcessing: false,
    errors: null,
    hasSelectedApprovedDevice: null,

    'availableEntities': [],
    'availableManufacturers': [],
    'availableDeviceModels': [],

    actions: {
      openCustomManuModelCreationModal: function openCustomManuModelCreationModal() {
        this.get('remodal').open('custom-manu-model-creation-modal');
      },
      save: function save(model) {
        var _this = this;

        this.setProperties({
          'isProcessing': true,
          'errors': null
        });
        model.save().then(function () {
          Materialize.toast('Device successfully created.', 2000); // 4000 is the duration of the toast
          window.history.go(-1);
          _this.setProperties({
            'isProcessing': false,
            'errors': null
          });
        }).catch(function (response) {
          _this.set('isProcessing', false);
          _this.set('errors', _this.get('errorParser').parseAndDisplay(response, 'box'));
        });
      },
      selectManufacturer: function selectManufacturer(selectedManufacturer, callback) {
        var _this2 = this;

        if (selectedManufacturer) {
          var device = this.get('model'),
              manufacturerName = selectedManufacturer.get('manufacturer');
          device.set('manufacturer', manufacturerName);
          this.set('hasSelectedApprovedDevice', selectedManufacturer.get('isApprovedManufacturer'));
          this.get('store').unloadAll('device-model');
          this.get('store').query('device-model', { manufacturer: manufacturerName }).then(function (deviceModels) {
            _this2.set('availableDeviceModels', deviceModels);
            if (typeof callback === "function") {
              callback();
            }
          });
        }
      },
      selectDeviceModel: function selectDeviceModel(selectedModel) {
        var device = this.get('model'),
            deviceModelName = selectedModel.get('model');

        device.set('model', deviceModelName);

        this.set('hasSelectedApprovedDevice', selectedModel.get('approvedDeviceId') ? true : false);

        if (this.get('hasSelectedApprovedDevice')) {
          device.set('approvedDeviceId', selectedModel.get('approvedDeviceId'));
          device.set('customDeviceId', null);
        } else {
          device.set('customDeviceId', selectedModel.get('customDeviceId'));
          device.set('approvedDeviceId', null);
        }
      },
      addNewCustomField: function addNewCustomField() {
        var customField = this.store.createFragment('custom-field', { name: 'Field Name', value: 'Value' });
        this.get('model.customFields').pushObject(customField);
      },
      removeCustomField: function removeCustomField(field) {
        this.get('model.customFields').removeObject(field);
        field.destroy();
      },
      reloadManufacturersAndSelect: function reloadManufacturersAndSelect(manufacturer, model) {
        var _this3 = this;

        this.store.findAll('manufacturer').then(function () {
          _this3.send('selectManufacturer', manufacturer, function () {
            _this3.send('selectDeviceModel', model);
          });
        });
      }
    }
  });
});