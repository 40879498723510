define('pinpoint-manager/models/survey-answer', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember-data-model-fragments'], function (exports, _attr, _relationships, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    audit: (0, _relationships.belongsTo)('audit'),
    question: (0, _attr.default)('string'),
    questionId: (0, _attr.default)('number'),
    answer: (0, _attr.default)('string')
  });
});