define('pinpoint-manager/routes/devices/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      location: {
        replace: true,
        refreshModel: true
      }
    },

    model: function model(params) {
      //return this.store.findAll('device');
      if (!this.get('currentUser.canAccessAllLocationsInDevices') && !params.location) {
        return null;
      } else {
        return this.store.query('device', { location: params.location });
      }
    },


    // setupController(controller, model) {
    //   this._super(controller, model);
    //   //let locations = this.store.findAll('location');
    //   let entities = this.store.query('entity', { entityType: "ZONE" });  
    //   controller.set('entities', entities);
    // },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('location', undefined);
      }
    },


    actions: {
      willTransition: function willTransition() {
        // Close filters view to prevent a bug where going back with filtersView open would show the data inside it empty
        this.controller.set('options.filtersView', false);
      }
    }
  });
});