define('pinpoint-manager/components/debounced-value', ['exports', 'ember-debounced-input-helpers/components/debounced-value'], function (exports, _debouncedValue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _debouncedValue.default;
    }
  });
});