define('pinpoint-manager/adapters/audit', ['exports', 'pinpoint-manager/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    createRecord: function createRecord(store, type, snapshot) {
      var postData = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(postData, type, snapshot, { includeId: true });

      return this.ajax(url + '/' + snapshot._belongsToIds.device, 'POST', { data: postData });
    }
  });
});