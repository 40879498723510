define("pinpoint-manager/utils/paths", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		return {
			rootURL: function rootURL() {
				return "/";
			},

			namespace: 'api/v1',
			host: function host() {
				return protocol + "//" + _host;
			},
			cookieDomain: function cookieDomain() {
				if (isDevelopment) {
					return ".zerorisk.local";
				} else {
					return ".zerorisk.io";
				}
			}
		};
	};

	var _host = window.location.host;
	var protocol = window.location.protocol;
	var isDevelopment = _host.indexOf("local") !== -1 || _host.indexOf("127.0.0.1") !== -1;
});