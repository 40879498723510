define('pinpoint-manager/controllers/auth/login-url', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: "qr",

    alerting: (0, _inject.default)(),
    session: (0, _inject.default)(),
    router: (0, _inject.default)('-routing'),

    authenticate: function authenticate(token) {
      var _this = this;

      return this.get('session').authenticate('authenticator:cookie', { token: token }).then(function () {
        _this.get('alerting').notify('Successfully authenticated.');
      }, function () {
        //this.controllerFor('auth.login-url').set('error', "The login URL you just tried to use is either expired or incorrect. Please try again.");
        _this.get('alerting').notify("The login URL you just tried to use is either expired or incorrect. Please try again.");
        _this.get('router').transitionTo('login');
      });
    },


    actions: {
      authenticate: function authenticate() {
        this.authenticate.apply(this, arguments);
      }
    }
  });
});