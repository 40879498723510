define('pinpoint-manager/components/tween-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var $ = Ember.$;

  exports.default = Ember.Component.extend({
    tagName: "span",

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // debugger;

      $(this).prop('Counter', 0).animate({
        Counter: this.$().text()
      }, {
        duration: 700,
        easing: 'swing',
        step: function step(now) {
          if (this.$() && this.$().text() !== "") {
            this.$().text(Math.ceil(now));
          }
        }
      });
    }
  });
});