// export default function(){

// 	this.transition(
// 	  this.hasClass('areas-list'),

// 	  this.toValue(false),
// 	  this.use('toLeft'),

// 	  // this.reverse('toRight')
// 	);
// };

// const duration = 200;

// this.transition(
//   this.fromRoute('devices'),
//   this.toRoute('audit'),
//   // this.use('toLeft'),
//   this.use('explode', {
//     matchBy: 'data-device-id',
//     use: ['flyTo', { duration } ]
//   }, {
//     use: ['toLeft', { duration } ]
//   }),
//   this.reverse('explode', {
//     matchBy: 'data-device-id',
//     use: ['flyTo', { duration } ]
//   }, {
//     use: ['toRight', { duration } ]
//   }),
//   // this.reverse('toRight'),
//   this.debug()
// );
define("pinpoint-manager/transitions", [], function () {
  "use strict";
});