define('pinpoint-manager/session-stores/application', ['exports', 'ember-simple-auth/session-stores/cookie', 'pinpoint-manager/utils/paths'], function (exports, _cookie, _paths) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _cookie.default.extend({
		cookieName: 'zr:session',
		cookieDomain: (0, _paths.default)().cookieDomain()
	});
});