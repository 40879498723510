define('pinpoint-manager/models/role', ['exports', 'ember-data/model', 'ember-data/attr', 'pinpoint-manager/language'], function (exports, _model, _attr, _language) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      computed = _Ember.computed;
  exports.default = _model.default.extend({
    roleType: (0, _attr.default)('string'),
    humanName: computed('roleType', function () {
      return _language.default.roles[this.get('roleType')].name;
    })
  });
});