define('pinpoint-manager/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-ajax/mixins/ajax-support', 'pinpoint-manager/utils/paths'], function (exports, _emberData, _dataAdapterMixin, _ajaxSupport, _paths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, _ajaxSupport.default, {
    host: (0, _paths.default)().host(),
    namespace: (0, _paths.default)().namespace + '/pinpoint',

    authorizer: 'authorizer:cookie',

    keyForRelationship: function keyForRelationship(key) {
      return key + 'Id';
    }

    // isSuccess(status, headers, payload) {
    //   // return status >= 200 && status < 300 || status === 304;
    //   return payload.success;
    // },

    // isInvalid(status) {
    //   return status === 400 || status === 422;
    // }

  });
});