define('pinpoint-manager/components/modals/custom-manu-model-creation', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: (0, _inject.default)(),
    remodal: (0, _inject.default)(),
    ajax: (0, _inject.default)(),
    errorParser: (0, _inject.default)(),

    newManufacturer: null,
    newDeviceModel: null,
    manufacturers: null,
    deviceModels: null,

    canSave: Ember.computed.and('newManufacturer', 'newDeviceModel'),

    unavailableDeviceModels: Ember.computed('deviceModels', function () {
      var deviceModels = this.get('deviceModels');
      if (deviceModels) {
        return deviceModels.map(function (dm) {
          dm.set('disabled', true);
          return dm;
        });
      }
    }),

    actions: {
      selectManufacturer: function selectManufacturer(selectedManufacturer) {
        var _this = this;

        if (selectedManufacturer) {
          this.set('newManufacturer', selectedManufacturer);
          this.set('newDeviceModel', null);
          this.get('store').unloadAll('device-model');
          this.get('store').query('device-model', { manufacturer: selectedManufacturer.get('manufacturer') }).then(function (deviceModels) {
            _this.set('deviceModels', deviceModels);
          });
        }
      },
      createManufacturer: function createManufacturer(newManufacturer) {
        this.set('newManufacturer', Ember.Object.create({ manufacturer: newManufacturer }));
        this.set('newDeviceModel', null);
      },
      createDeviceModel: function createDeviceModel(newDeviceModel) {
        this.set('newDeviceModel', Ember.Object.create({ model: newDeviceModel }));
      },
      save: function save() {
        var _this2 = this;

        var manufacturer = this.get('newManufacturer').get('manufacturer'),
            model = this.get('newDeviceModel').get('model');

        this.get('ajax').post('pinpoint/resources/manufacturersAndModels', {
          data: JSON.stringify({ 'manufacturerAndModel': { manufacturer: manufacturer, model: model } }),
          contentType: 'application/json'
        }).then(function () {
          _this2.set('errors', null);
          _this2.send('close');
          _this2.get('onComplete')(_this2.get('newManufacturer'), _this2.get('newDeviceModel'));
        }).catch(function (response) {
          _this2.set('errors', _this2.get('errorParser').parseAndDisplay(response, 'box'));
        });
      },
      close: function close() {
        this.get('remodal').close('custom-manu-model-creation-modal');
      },
      reset: function reset() {
        this.setProperties({
          newManufacturer: false,
          newDeviceModel: null,
          deviceModels: null,
          errors: null
        });
      },
      buildSuggestionForManufacturer: function buildSuggestionForManufacturer(term) {
        return Ember.String.htmlSafe('<div class="chip">Create Manufacturer "<b>' + term + '</b>"..</div>');
      },
      buildSuggestionForModel: function buildSuggestionForModel(term) {
        return Ember.String.htmlSafe('<div class="chip">Create Model "<b>' + term + '</b>"..</div>');
      }
    }
  });
});