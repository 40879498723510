define('pinpoint-manager/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),

    // Hooks
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.get('session').beforeApplication(arguments[0]);
    },
    activate: function activate() {
      if (typeof FastBoot === 'undefined') {
        Ember.run.scheduleOnce('afterRender', this, function () {
          // Init tooltips
          Ember.$('.tooltipped').tooltip({ delay: 0 });
          // Init side navigation panel
          Ember.$('.button-collapse').sideNav({ closeOnClick: true });
          // Adding flex-container class to first .ember-view
          Ember.$('.ember-view:not(.liquid-target-container)').first().addClass('flex-container');
        });
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this.get('session').afterAuthentication();
    },


    // Overwriting 'sessionInvalidated' with a custom function to prevent location reloading and giving user custom messages
    sessionInvalidated: function sessionInvalidated() {
      this.get('session').afterInvalidation();
    },


    actions: {
      openModal: function openModal(name, model) {
        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model || null
        });
      },
      removeModal: function removeModal() {
        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }
    }
  });
});