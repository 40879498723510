define('pinpoint-manager/authorizers/cookie', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _base.default.extend({
    session: service(),

    authorize: function authorize(jqXHR, requestOptions) {
      requestOptions.contentType = 'application/json; charset=utf-8';
      requestOptions.crossDomain = true;
      requestOptions.xhrFields = {
        withCredentials: true
      };
      // this.get('session').setTimeOfLastAPIActivity();
      // Ember.Logger.debug('cookie.authorize: setTimeOfLastAPIActivity()');
    }
  });
});