define('pinpoint-manager/routes/devices/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'pinpoint-manager/config/environment'], function (exports, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      if (!this.get('currentUser.canCreateDevices')) {
        transition.abort();
        this.transitionTo(_environment.default['ember-simple-auth'].routeAfterAuthentication);
      }
    },
    model: function model() {
      return this.store.createRecord('device');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      //controller.set('availableLocations', this.store.findAll('location'));
      this.store.findAll('entity').then(function (entities) {
        controller.set('availableEntities', entities.filterBy('isLocation'));
      });
      //controller.set('availableManufacturers', this.store.findAll('manufacturer'));
      this.store.findAll('manufacturer').then(function (manufacturers) {
        controller.set('availableManufacturers', manufacturers);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // Reset controller state
        controller.setProperties({
          'isProcessing': false,
          'errors': null,
          'availableEntities': [],
          'availableManufacturers': [],
          'availableDeviceModels': []
        });
      }
    },


    actions: {
      willTransition: function willTransition() {
        this.currentModel.unloadRecord();
      }
    }
  });
});