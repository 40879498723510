define('pinpoint-manager/components/debounced-input', ['exports', 'ember-debounced-input-helpers/components/debounced-input'], function (exports, _debouncedInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _debouncedInput.default;
    }
  });
});