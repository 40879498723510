define('pinpoint-manager/components/ember-remodal', ['exports', 'ember-remodal/components/ember-remodal'], function (exports, _emberRemodal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberRemodal.default;
    }
  });
});