define('pinpoint-manager/services/logger', ['exports', 'ember-cli-sentry/services/raven', 'ember-metal/get'], function (exports, _raven, _get) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _raven.default.extend({
    errorsToIgnore: ['TransitionAborted'],

    ignoreError: function ignoreError(error) {
      var message = error.message;

      return (0, _get.default)(this, 'errorsToIgnore').any(function (ignored) {
        return message.indexOf(ignored) >= 0;
      });
    }
  });
});