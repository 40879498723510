define('pinpoint-manager/controllers/devices/index', ['exports', 'ember-service/inject', 'pinpoint-manager/utils/paths', 'ember-cli-pagination/computed/paged-array'], function (exports, _inject, _paths, _pagedArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var $ = Ember.$;

  exports.default = Ember.Controller.extend({
    remodal: (0, _inject.default)(),
    files: (0, _inject.default)(),

    queryParams: ['location', 'page', 'perPage'],

    init: function init() {
      this._super();

      this.set('options', Ember.Object.create({
        cardsView: true,
        listView: false,
        filtersView: false,
        //hasSelectedAllLocations: true,
        hasSelectedAllManufacturers: true,
        hasSelectedAllDeviceModels: true,
        selectedManufacturers: Ember.A([]),
        selectedDeviceModels: Ember.A([]),
        //selectedLocations: Ember.A([]),
        nonCompliantOnly: false,
        showDisabled: false
      }));
    },


    //location: null,

    searchQuery: null,

    perLocationNonCompliantDevicesCount: 0,
    perLocationTotalDevicesCount: 0,

    // locations: Ember.computed('entities.[]', function () {
    //   return this.get('entities').getEach('contactInfo.address');
    // }),

    currentEntity: Ember.computed('location', function () {
      return this.store.findRecord('entity', this.get('location'));
    }),

    manufacturers: Ember.computed('model.@each.manufacturer', 'location', function () {
      return this.get('model').getEach('manufacturer').uniq();
    }),
    didSelectAllManufacturers: Ember.observer('options.hasSelectedAllManufacturers', function () {
      if (this.get('options.selectedManufacturers.length') > 0 && this.get('options.hasSelectedAllManufacturers')) {
        this.set('options.selectedManufacturers', Ember.A([]));
      }
    }),
    didSelectAManufacturer: Ember.observer('options.selectedManufacturers.length', function () {
      if (this.get('options.selectedManufacturers.length') > 0) {
        this.set('options.hasSelectedAllManufacturers', false);
      } else if (this.get('options.selectedManufacturers.length') === 0) {
        this.set('options.hasSelectedAllManufacturers', true);
      }
    }),

    models: Ember.computed('model.@each.model', function () {
      return this.get('model').getEach('model').uniq();
    }),
    didSelectAllDeviceModels: Ember.observer('options.hasSelectedAllDeviceModels', function () {
      if (this.get('options.selectedDeviceModels.length') > 0 && this.get('options.hasSelectedAllDeviceModels')) {
        this.set('options.selectedDeviceModels', Ember.A([]));
      }
    }),
    didSelectADeviceModel: Ember.observer('options.selectedDeviceModels.length', function () {
      if (this.get('options.selectedDeviceModels.length') > 0) {
        this.set('options.hasSelectedAllDeviceModels', false);
      } else if (this.get('options.selectedDeviceModels.length') === 0) {
        this.set('options.hasSelectedAllDeviceModels', true);
      }
    }),

    // locationDevices: Ember.computed('model.[]', 'model.@each.entity', 'currentEntity', function() {
    //   if (this.get('currentEntity')) {
    //     return this.get('model').filterBy('entity.id', this.get('currentEntity.id'));
    //   } else {
    //     return this.get('model');
    //   }
    // }),

    filteredDevices: Ember.computed('searchQuery', 'model.[]',
    // 'model.@each.entity',
    //'locationDevices',
    'model.@each.isEnabled',
    //'location',
    'options.selectedManufacturers.length', 'options.selectedDeviceModels.length', 'options.selectedLocations.length', 'options.filtersView', 'options.hasSelectedAllLocations', 'options.hasSelectedAllManufacturers', 'options.hasSelectedAllDeviceModels', 'options.nonCompliantOnly', 'options.showDisabled', function () {
      var _this = this;

      // Go to page 1 whenever the devices lenght changes or one of the filtering criterias are updated
      this.set('page', 1);
      Ember.$("body").css("padding", 0);

      var devices = this.get('model'),

      //location                                   =  this.get('location'),
      searchQuery = this.get('searchQuery'),
          selectedManufacturers = this.get('options.selectedManufacturers'),
          selectedDeviceModels = this.get('options.selectedDeviceModels'),
          hasSelectedAllManufacturers = this.get('options.hasSelectedAllManufacturers'),
          hasSelectedAllDeviceModels = this.get('options.hasSelectedAllDeviceModels'),
          hasSelectedAllComplianceStatuses = !this.get('options.nonCompliantOnly'),
          hasSelectedToShowDisabled = this.get('options.showDisabled');

      // if (location) {
      //   // this.get('entities').then(entities => { 
      //   //   devices = devices.filterBy('entity',  entities.findBy('id', location));
      //   // });
      //   console.log(devices.filterBy('entity',  this.get('currentLocationObject')));
      //   devices = devices.filterBy('entity',  this.get('currentLocationObject'));

      // }

      // Setting some properties used elsewhere before any optional filter may occur
      // TODO: refactor this into computed props
      //this.set('locationFilteredDevices', devices);

      if (devices) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this.set('perLocationTotalDevicesCount', devices.filterBy('isEnabled').get('length'));
          _this.set('perLocationNonCompliantDevicesCount', devices.filterBy('isDeviceCompliant', false).filterBy('isEnabled').get('length'));
        });

        // remove devices that don't have a truthy id
        devices = devices.filterBy('id');

        if (searchQuery) {
          this.set('options.filtersView', false);
          return devices.filter(function (device) {
            return device.get('manufacturer').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || device.get('model').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || device.get('serial').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || device.get('notes') && device.get('notes').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || device.get('tag') && device.get('tag').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
          });
        } else if (this.get('options.filtersView')) {
          return devices.filter(function (device) {
            return (selectedManufacturers.includes(device.get('manufacturer')) || hasSelectedAllManufacturers) && (selectedDeviceModels.includes(device.get('model')) || hasSelectedAllDeviceModels) && (!device.get('isDeviceCompliant') || hasSelectedAllComplianceStatuses) && (device.get('isEnabled') || hasSelectedToShowDisabled);
          });
        } else {
          return devices.filterBy('isEnabled');
        }
      }
    }),

    sortProperties: ['numericId:desc'],

    sortedAndFilteredDevices: Ember.computed.sort('filteredDevices', 'sortProperties'),

    page: 1,
    perPage: 3,
    sortedAndFiltereAndPagedDevices: (0, _pagedArray.default)('sortedAndFilteredDevices', {
      page: Ember.computed.alias("parent.page"),
      perPage: Ember.computed.alias("parent.perPage")
    }),
    totalPages: Ember.computed.oneWay("sortedAndFiltereAndPagedDevices.totalPages"),

    actions: {
      downloadQuickAudit: function downloadQuickAudit(device) {
        var queryParams = 'deviceId=' + device.get('id');
        this.get('files').download('api/v2/shared/public/download/pinpoint/quickAuditHistory', queryParams);
      },
      downloadDevicesTableReport: function downloadDevicesTableReport() {
        var devices = this.get('filteredDevices');
        var devicesQueryParamString = '';
        if (devices) {
          var deviceIds = devices.mapBy('id');
          devicesQueryParamString = 'deviceIds=' + deviceIds;
        }

        this.get('files').download('api/v2/shared/public/download/pinpoint/device', devicesQueryParamString);
      },
      downloadComplianceReport: function downloadComplianceReport() {
        this.get('files').download('api/v2/shared/public/download/pinpoint/complianceReport');
      },
      changeViewMode: function changeViewMode(viewMode) {
        if (viewMode === 'cardsView') {
          this.set('options.cardsView', true);
          this.set('options.listView', false);
        } else {
          this.set('options.cardsView', false);
          this.set('options.listView', true);

          Ember.run.scheduleOnce('afterRender', this, function () {
            $('.devices-table-action-button').dropdown();
            // $('.modal-trigger').leanModal();
          });
        }
      },
      toggleFiltersView: function toggleFiltersView() {
        this.toggleProperty('options.filtersView');
        if (this.get('options.filtersView')) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            $('.collapsible').collapsible({ accordion: false });
          });
        }
      },
      showNotComplianctDevicesOnly: function showNotComplianctDevicesOnly() {
        this.set('options.filtersView', true);
        this.set('options.nonCompliantOnly', true);
        // TODO: find a better view to detect that .collapsible is showing and activate the plugin
        Ember.run.scheduleOnce('afterRender', this, function () {
          $('.collapsible').collapsible({ accordion: false });
        });
      },
      openLocationEntitySelectorModal: function openLocationEntitySelectorModal() {
        this.get('remodal').open('location-entity-selector-modal');
      },
      closeLocationEntitySelectorModal: function closeLocationEntitySelectorModal() {
        this.get('remodal').close('location-entity-selector-modal');
      },
      setCurrentLocationEntityId: function setCurrentLocationEntityId(locationEntity) {
        this.set('location', locationEntity.get('id'));
      }
    }
  });
});