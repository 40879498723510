define('pinpoint-manager/serializers/entity', ['exports', 'pinpoint-manager/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      contactInfo: { embedded: 'always' },
      users: { serialize: false }
    },

    keyForRelationship: function keyForRelationship(key) {
      return key;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      // Needed to remove group from the store if null, in order to use the defaultValue set in the address model
      if (payload.entities.contactInfo.address.group === null) {
        delete payload.entities.contactInfo.address.group;
      }
      return this._super(store, type, payload);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, type, payload) {
      // Needed to remove group from the store if null, in order to use the defaultValue set in the address model
      payload.entities.forEach(function (entity) {
        if (entity.contactInfo.address.group === null) {
          delete entity.contactInfo.address.group;
        }
      });

      return this._super(store, type, payload);
    }
  });
});