define('pinpoint-manager/routes/audit/view/survey', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject'], function (exports, _authenticatedRouteMixin, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: (0, _inject.default)(),

    model: function model() {
      //return this.store.find('audit', this.paramsFor('audit.view').device_id);
      return this.modelFor('audit.view');
    },
    afterModel: function afterModel(model) {
      // Inflate question texts in surveyAnswrs from API
      this.get('ajax').request('pinpoint/survey').then(function (response) {
        var surveyQuestions = response.result.survey.connections;
        surveyQuestions.forEach(function (surveyQuestion) {
          var surveyAnswer = model.get('surveyAnswers').findBy('questionId', surveyQuestion.questionId);
          surveyAnswer.set('question', surveyQuestion.text);
        });
      });
    }
  });
});