define('pinpoint-manager/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //fastboot: Ember.inject.service(),

    activate: function activate() {
      if (typeof FastBoot === 'undefined') {
        Ember.run.schedule('afterRender', this, function () {
          var video = document.querySelector('video');
          video.muted = true;
        });
      }
    }
  });
});