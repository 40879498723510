define('pinpoint-manager/initializers/error-parser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'errorParser', 'service:errorParser');
  }

  exports.default = {
    name: 'error-parser',
    initialize: initialize
  };
});