define('pinpoint-manager/router', ['exports', 'ember-router-scroll', 'pinpoint-manager/config/environment'], function (exports, _emberRouterScroll, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.reopen({
    urlDidChange: Ember.on('didTransition', function () {
      if (typeof FastBoot === 'undefined') {
        // TODO: find a better way to remove tooltips out of the way when changing route
        Ember.$('.material-tooltip').remove();
        Ember.run.schedule('afterRender', this, function () {
          // setTimeout( function() {
          // Add tooltip
          Ember.$('.tooltipped').tooltip({ delay: 0 });
          // }, 100);
          // Add dropdowns
          Ember.$('.dropdown-button').dropdown();
        });
      }
    })
  });

  Router.map(function () {
    this.route('protected');

    this.route('login', { path: '/login' });

    this.route('dashboard');

    this.route('devices', { resetNamespace: true }, function () {
      this.route('new');
      this.route('edit', { path: ':device_id/edit' });
    });

    this.route('audit', { resetNamespace: true }, function () {
      this.route('new', { path: ':device_id/new' });
      this.route('view', { path: ':audit_id' }, function () {
        this.route('compliance');
        this.route('inspection');
        this.route('photos');
        this.route('survey');
      });
    });

    this.route('locations', { resetNamespace: true });

    this.route('admin', { resetNamespace: true }, function () {
      this.route('users');
    });

    this.route('registration', { path: 'signup/:mode' });

    this.route('user', { resetNamespace: true }, function () {
      this.route('verify', { path: 'verify/:token' });
      this.route('new', { path: 'new/:token' });
      this.route('recover', function () {
        this.route('request');
        this.route('reset', { path: 'reset/:token' });
      });
    });

    this.route('auth', function () {
      this.route('login-url', { path: 'login-url/:token' });
    });

    this.route('legal', function () {
      this.route('privacy-policy');
      this.route('terms-of-use');
      this.route('terms-and-conditions');
    });

    this.route('not-found', { path: '/*path' });
    this.route('error');
  });

  exports.default = Router;
});