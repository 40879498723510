define('pinpoint-manager/controllers/registration', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var $ = Ember.$;

  exports.default = Ember.Controller.extend(_emberValidations.default, {

    //tour: Ember.inject.service(),

    isLoading: false,

    user: Ember.Object.create(),
    contactInfo: Ember.Object.create(),
    address: Ember.Object.create(),
    hubspotInfo: Ember.Object.create(),

    primaryContactIsTheSameAsUser: true,

    activeStep: 'plans',
    activeStepTemplate: Ember.computed('activeStep', function () {
      return 'registration/' + this.get('activeStep');
    }),

    inPlans: Ember.computed.equal('activeStep', 'plans'),
    inUserInfo: Ember.computed.equal('activeStep', 'user-info'),
    inPayment: Ember.computed.equal('activeStep', 'payment'),
    inConfirmation: Ember.computed.equal('activeStep', 'confirmation'),

    chosenPlan: null,
    chosenPlanIsFree: Ember.computed('chosenPlan', function () {
      return this.get('chosenPlan') === 'micro';
    }),
    chosenPlanPricePerYearInEuro: Ember.computed('chosenPlan', function () {
      return this.get('availablePlans').get(this.get('chosenPlan')).get('pricePerYearInEuroCents') / 100;
    }),
    chosenPlanPricePerDeviceInEuro: Ember.computed('chosenPlan', function () {
      return this.get('availablePlans').get(this.get('chosenPlan')).get('pricePerDeviceInEuroCents') / 100;
    }),
    chosenPlanMaxNumOfDevices: Ember.computed('chosenPlan', function () {
      return this.get('availablePlans').get(this.get('chosenPlan')).get('maxNumOfDevices');
    }),

    purchasedPinpointDevices: 1,

    hasCompletedRegistration: false,

    showAllValidationErrors: false,

    serverErrors: null,

    emailServerErrors: null,
    contactInfoEmailServerErrors: null,
    emailIsServerApproved: false,
    contactInfoEmailIsServerApproved: false,
    companyNameServerErrors: null,
    companyNameIsServerApproved: false,

    validations: {

      'user.email': {
        presence: true,
        format: {
          with: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
          message: 'not a valid e-mail address'
        }
      },
      'user.firstName': { presence: true },
      'user.lastName': { presence: true },
      // 'user.title':  { presence: true },
      'user.companyName': { presence: true },
      'user.password': {
        presence: true,
        confirmation: true,
        length: { minimum: 8 }
      },
      'user.passwordConfirmation': {
        presence: {
          message: 'confirm your password'
        }
      },
      'contactInfo.email': {
        presence: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          }
        },
        format: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          },

          with: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
          message: 'not a valid e-mail address'
        }
      },
      'contactInfo.contactName': {
        presence: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          }
        }
      },
      'contactInfo.dba': {
        presence: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          }
        }
      },
      'contactInfo.title': {
        presence: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          }
        }
      },
      'contactInfo.phone': {
        presence: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          }
        }
      },
      'contactInfo.fax': {
        presence: {
          if: function _if(object) {
            return !object.get('primaryContactIsTheSameAsUser');
          }
        }
      },
      'hubspotInfo.hs_persona': { presence: true }

    },

    rerunValidation: Ember.observer('primaryContactIsTheSameAsUser', function () {
      this.validate();
    }),

    actions: {
      goToStep: function goToStep(step) {
        // Cancel tour
        // if(  this.get('activeStep') === 'user-info' && step !== 'user-info' ) {
        //  if( this.get('tour').isActive ) {
        //    this.get('tour').trigger('cancel');
        //  }
        // }

        if (!this.get('chosenPlan')) {
          Materialize.toast('Choose a plan first!', 2000);
          return;
        }

        if (step === 'payment' && !this.get('isValid')) {
          Materialize.toast('Please provide valid user informations first!', 2000);
          return;
        }

        if (step !== 'confirmation' && this.get('hasCompletedRegistration')) {
          Materialize.toast('You already completed the registration! Check your inbox for email confirmation.', 2000);
          this.send('goToStep', 'confirmation');
          return;
        }

        if (step === 'confirmation' && !this.get('hasCompletedRegistration')) {
          Materialize.toast('Please complete all the steps first!', 2000);
          return;
        }

        if (step === 'payment') {
          this.set('isLoading', true);
          // Open Stripe Modal
          this.get('stripeHandler').open({
            name: 'NTT Security Ltd',
            description: 'ZeroRisk PINPoint (' + this.get('chosenPlan').toUpperCase() + ' plan)',
            currency: 'eur',
            amount: this.get('availablePlans').get(this.get('chosenPlan')).get('pricePerYearInEuroCents') + this.get('purchasedPinpointDevices') * this.get('availablePlans').get(this.get('chosenPlan')).get('pricePerDeviceInEuroCents'),
            email: this.get('user.email')
          });

          this.set('isLoading', false);
        }

        if (step === 'user-info') {
          this.send('initRangePlugin');
          if (this.get('chosenPlan') !== 'micro') {
            //this.send('initTour');
          }
        }

        if (step === 'plans') {
          this.send('initTooltipPlugin');
        }

        this.set('activeStep', step);
      },
      choosePlan: function choosePlan(plan) {
        var _this = this;

        this.set('chosenPlan', plan);
        // Fancy shit
        $('.plan-' + plan).velocity('transition.bounceOut');
        $('.rate-card').not('.plan-' + plan).velocity('transition.slideUpOut', { complete: function complete() {
            _this.send('goToStep', 'user-info');
            Ember.run.schedule('afterRender', _this, function () {
              $('.chosen-plan-card').velocity('transition.slideDownIn');
            });
          }
        });
      },
      validateEmailFromServer: function validateEmailFromServer() {
        var _this2 = this;

        var email = this.get('user.email');
        // Check if email is already taken (server-side)
        Ember.$.ajax({
          type: 'POST',
          async: true,
          url: '/api/v1/pinpoint/users/email_availability_check',
          contentType: 'application/json',
          data: JSON.stringify({ email: email })
        }).then(function (response) {
          if (response.success) {
            if (!response.result.result) {
              //Materialize.toast('This email has already been taken, please choose a different one.', 2000);
              _this2.set('emailServerErrors', 'email already taken, please choose a different one');
              _this2.set('emailIsServerApproved', false);
              return;
            } else {
              _this2.set('emailServerErrors', null);
              _this2.set('emailIsServerApproved', true);
            }
          }
        });
      },
      validateContactInfoEmailFromServer: function validateContactInfoEmailFromServer() {
        var _this3 = this;

        var email = this.get('contactInfo.email');
        // Check if email is already taken
        Ember.$.ajax({
          type: 'POST',
          async: true,
          url: '/api/v1/pinpoint/users/email_availability_check',
          contentType: 'application/json',
          data: JSON.stringify({ email: email })
        }).then(function (response) {
          if (response.success) {
            if (!response.result.result) {
              //Materialize.toast('This email has already been taken, please choose a different one.', 2000);
              _this3.set('contactInfoEmailServerErrors', 'email already taken, please choose a different one');
              _this3.set('contactInfoEmailIsServerApproved', false);
              return;
            } else {
              _this3.set('contactInfoEmailServerErrors', null);
              _this3.set('contactInfoEmailIsServerApproved', true);
            }
          }
        });
      },
      validateCompanyNameFromServer: function validateCompanyNameFromServer() {
        var _this4 = this;

        var companyName = this.get('user.companyName');
        // Check if company name already exists
        Ember.$.ajax({
          type: 'POST',
          async: true,
          url: '/api/v1/pinpoint/users/companyName_availability_check',
          contentType: 'application/json',
          data: JSON.stringify({ companyName: companyName })
        }).then(function (response) {
          if (response.success) {
            if (!response.result.result) {
              //Materialize.toast('This email has already been taken, please choose a different one.', 2000);
              _this4.set('companyNameServerErrors', 'an account registered with this company name already exists, please choose a different one');
              _this4.set('companyNameIsServerApproved', false);
              return;
            } else {
              _this4.set('companyNameServerErrors', null);
              _this4.set('companyNameIsServerApproved', true);
            }
          }
        });
      },
      validateUserInfo: function validateUserInfo() {

        // Perform one more client side verification
        if (!this.get('isValid') || !this.get('emailIsServerApproved') || !this.get('companyNameIsServerApproved')) {
          Materialize.toast('Please check all inputs for errors before continuing!', 2000);
          this.set('showAllValidationErrors', true);
          return;
        }

        if (!this.get('primaryContactIsTheSameAsUser') && !this.get('contactInfoEmailIsServerApproved')) {
          Materialize.toast('Please check all inputs for errors before continuing!', 2000);
          this.set('showAllValidationErrors', true);
          return;
        }

        if (this.get('chosenPlan') !== 'micro') {
          this.send('goToStep', 'payment');
        } else {
          this.send('register', null);
        }
      },
      register: function register(stripeToken) {
        var _this5 = this;

        this.set('errors', null);
        this.set('isLoading', true);

        /*
            Fields
        */

        // merchant (hidden)
        var merchant = {
          'level': null,
          'businessChannel': null,
          'businessType': null,
          'mccDescription': null,
          'mccCode': null,
          'billingType': null,
          'mid': null,
          'license': this.get('chosenPlan').toUpperCase(),
          'purchasedPinpointDevices': this.get('purchasedPinpointDevices'),
          'userType': 'PINPOINT',
          'notifyUser': true
        };
        // Primary Contact
        var contactInfo;
        if (this.get('primaryContactIsTheSameAsUser')) {
          contactInfo = {
            'companyName': this.get('user.companyName') || null,
            'contactName': this.get('user.firstName') + ' ' + this.get('user.lastName') || null,
            'dba': 'DBA' || null,
            'title': this.get('hubspotPersonas').findBy('value', this.get('hubspotInfo.hs_persona')).text || null,
            'phone': '99999999' || null,
            'fax': '8888888' || null,
            'email': this.get('user.email') || null
          };
        } else {
          contactInfo = {
            'companyName': this.get('user.companyName') || null,
            'contactName': this.get('contactInfo.contactName') || null,
            'dba': this.get('contactInfo.dba') || null,
            'title': this.get('contactInfo.title') || null,
            'phone': this.get('contactInfo.phone') || null,
            'fax': this.get('contactInfo.fax') || null,
            'email': this.get('contactInfo.email') || null
          };
        }
        // Business (address)
        var address = {
          'businessAddress': 'PINPoint Placeholder' || null,
          'city': 'PINPoint Placeholder' || null,
          'stateProvince': 'PINPoint Placeholder' || null,
          'country': 'PINPoint Placeholder' || null,
          'zipCode': 'PINPoint Placeholder' || null,
          'url': 'PINPoint Placeholder' || null
        };
        //  Account User
        var user = {
          'email': this.get('user.email') || null,
          'firstName': this.get('user.firstName') || null,
          'lastName': this.get('user.lastName') || null,
          'title': this.get('hubspotPersonas').findBy('value', this.get('hubspotInfo.hs_persona')).text || null,
          'merchant': null,
          'password': this.get('user.password') || null,
          'passwordRepeat': this.get('user.passwordConfirmation') || null
        };

        // HubSpot Contact Informations
        var hubspotInfo = {
          'hsPersona': this.get('hubspotInfo.hs_persona')
        };

        // Send actual registration call
        Ember.$.ajax({
          type: 'POST',
          url: '/api/v1/pinpoint/users/sign_up',
          contentType: 'application/json',
          data: JSON.stringify({ merchant: merchant, contactInfo: contactInfo, address: address, user: user, hubspotInfo: hubspotInfo, stripeToken: stripeToken })
        }).then(function (response) {
          _this5.set('isLoading', false);
          _this5.set('serverErrors', null);
          if (response.errors) {
            _this5.set('serverErrors', Ember.Object.create(response.errors));
          } else {
            _this5.set('serverErrors', null);
            _this5.set('hasCompletedRegistration', true);
            _this5.send('goToStep', 'confirmation');
          }
        }).catch(function () {
          _this5.set('serverErrors', 'Something unexpected happened. Please contact support at support@zerorisk.io.');
        });
      },
      initRangePlugin: function initRangePlugin() {
        var _this6 = this;

        Ember.run.schedule('afterRender', this, function () {
          /* jshint ignore:start */
          var range_type = 'input[type=range]',
              range_mousedown = !1,
              left;$(range_type).each(function () {
            var e = $('<span class="thumb"><span class="value"></span></span>');$(this).after(e);
          });var range_wrapper = '.range-field';$(document).on('change', range_type, function () {
            var e = $(this).siblings('.thumb');e.find('.value').html($(this).val());
          }), $(document).on('mousedown touchstart', range_type, function (e) {
            var t = $(this).siblings('.thumb');t.length <= 0 && (t = $('<span class="thumb"><span class="value"></span></span>'), $(this).append(t)), t.find('.value').html($(this).val()), range_mousedown = !0, $(this).addClass('active'), t.hasClass('active') || t.velocity({ height: '30px', width: '30px', top: '-20px', marginLeft: '-15px' }, { duration: 300, easing: 'easeOutExpo' }), left = void 0 === e.pageX || null === e.pageX ? e.originalEvent.touches[0].pageX - $(this).offset().left : e.pageX - $(this).offset().left;var a = $(this).outerWidth();0 > left ? left = 0 : left > a && (left = a), t.addClass('active').css('left', left), t.find('.value').html($(this).val());
          }), $(document).on('mouseup touchend', range_wrapper, function () {
            range_mousedown = !1, $(this).removeClass('active');
          }), $(document).on('mousemove touchmove', range_wrapper, function (e) {
            var t,
                a = $(this).children('.thumb');if (range_mousedown) {
              a.hasClass('active') || a.velocity({ height: '30px', width: '30px', top: '-20px', marginLeft: '-15px' }, { duration: 300, easing: 'easeOutExpo' }), t = void 0 === e.pageX || null === e.pageX ? e.originalEvent.touches[0].pageX - $(this).offset().left : e.pageX - $(this).offset().left;var s = $(this).outerWidth();0 > t ? t = 0 : t > s && (t = s), a.addClass('active').css('left', t), a.find('.value').html(a.siblings(range_type).val());
            }
          }), $(document).on('mouseout touchleave', range_wrapper, function () {
            if (!range_mousedown) {
              var e = $(this).children('.thumb');e.hasClass('active') && e.velocity({ height: '0', width: '0', top: '10px', marginLeft: '-6px' }, { duration: 100 }), e.removeClass('active');
            }
          });
          /* jshint ignore:end */
          //var $devices_wanted_number_form = $("#devices-wanted-number-form");
          //var $devices_wanted_number_value = $("#devices-wanted-number .value");
          var $devices_wanted_input = Ember.$('#devices-wanted-input');
          if (_this6.get('purchasedPinpointDevices') > _this6.get('chosenPlanMaxNumOfDevices')) {
            _this6.set('purchasedPinpointDevices', _this6.get('chosenPlanMaxNumOfDevices'));
          }
          $devices_wanted_input.val(_this6.get('purchasedPinpointDevices'));
          $devices_wanted_input.on('change', function () {
            _this6.set('purchasedPinpointDevices', parseInt($devices_wanted_input.val()));
            //this.tour.trigger('cancel');
            $('.drag-me').hide();
          });
        });
      },
      initTooltipPlugin: function initTooltipPlugin() {
        Ember.run.schedule('afterRender', this, function () {
          $('.tooltipped').tooltip({ delay: 0 });
        });
      }
    }

  });
});