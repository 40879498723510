define('pinpoint-manager/models/audit', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _relationships, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    device: (0, _relationships.belongsTo)('device'),
    //deviceId: attr('number'),
    cables: (0, _attributes.fragmentArray)('cable'),
    photos: (0, _attributes.fragmentArray)('photo'),
    software: (0, _attr.default)('string'),
    marksOrStickers: (0, _attr.default)('string'),
    visualInspection: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    surveyAnswers: (0, _attributes.fragmentArray)('survey-answer')
  });
});