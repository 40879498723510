define("pinpoint-manager/helpers/audit-survey-answer-formatter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.auditSurveyAnswerFormatter = auditSurveyAnswerFormatter;
  function auditSurveyAnswerFormatter(answer) {
    var _answer = answer[0];

    if (_answer === 1 || _answer === "1") {
      return new Ember.String.htmlSafe("Yes");
    } else if (_answer === 0 || _answer === "0") {
      return new Ember.String.htmlSafe("No");
    } else {
      return new Ember.String.htmlSafe(_answer);
    }
  }

  exports.default = Ember.Helper.helper(auditSurveyAnswerFormatter);
});