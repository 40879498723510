define('pinpoint-manager/models/address', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    tag: {
      description: 'Tag',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 1, max: 30 })]
    },
    businessAddress: {
      description: 'Business Address',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 1, max: 30 })]
    },
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 1, max: 30 })]
    },

    stateProvince: {
      description: "State/Province",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    country: {
      description: 'Country',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 1, max: 30 })]
    },
    zipCode: {
      description: "ZIP Code",
      validators: [(0, _emberCpValidations.validator)('length', { min: 1, max: 30 })]
    },
    url: {
      description: "URL",
      validators: [(0, _emberCpValidations.validator)('format', { type: 'url' }), (0, _emberCpValidations.validator)('length', { min: 3, max: 30 })]
    }
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    tag: _emberData.default.attr('string'),
    group: _emberData.default.attr('string', { defaultValue: 'World' }),
    lat: _emberData.default.attr('number'),
    lng: _emberData.default.attr('number'),
    businessAddress: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    stateProvince: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    composedAddress: Ember.computed('businessAddress', function () {
      if (this.get('businessAddress') && this.get('zipCode')) {
        return this.get('businessAddress') + ', ' + this.get('zipCode') + ' ' + this.get('city') + ', ' + this.get('country');
      } else if (this.get('businessAddress')) {
        return this.get('businessAddress') + ', ' + this.get('city') + ', ' + this.get('country');
      }
    })
  });
});