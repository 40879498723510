define('pinpoint-manager/components/pp-navbar', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    navi: (0, _inject.default)(),
    session: (0, _inject.default)(),
    search: (0, _inject.default)(),
    remodal: (0, _inject.default)(),

    actions: {
      openCurrentUserEditModal: function openCurrentUserEditModal() {
        this.get('remodal').open('edit-current-user');
      }
    }
  });
});