define('pinpoint-manager/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy;


  var ProxyService = ObjectProxy.extend({
    content: Ember.Object.create() // To be filled with the user on login.
  });

  ProxyService.reopenClass({
    isServiceFactory: true
  });

  exports.default = ProxyService;
});