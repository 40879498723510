define('pinpoint-manager/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('identification', null);
        controller.set('password', null);
        controller.set('error', null);
        controller.set('isLoading', false);
      }
    }
  });
});