define('pinpoint-manager/routes/devices/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(parameters) {
      return this.store.findRecord('device', parameters.device_id);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this.store.query('device-model', { manufacturer: model.get('manufacturer') }).then(function (deviceModels) {
        _this.controller.set('availableDeviceModels', deviceModels);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('hasSelectedApprovedDevice', model.get('isApprovedDevice'));

      this.store.findAll('entity').then(function (entities) {
        controller.set('availableEntities', entities.filterBy('isLocation'));
      });
      this.store.findAll('manufacturer').then(function (manufacturers) {
        controller.set('availableManufacturers', manufacturers);
      });
      controller.setProperties({
        availableEntities: [],
        availableManufacturers: [],
        availableDeviceModels: []
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // Reset controller state
        controller.setProperties({
          'isProcessing': false,
          'errors': null
        });
      }
    },


    actions: {
      willTransition: function willTransition() {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});