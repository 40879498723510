define('pinpoint-manager/services/session', ['exports', 'ember-simple-auth/services/session', 'moment', 'pinpoint-manager/config/environment'], function (exports, _session, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    store: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    alerting: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    // Parameters
    sessionDuration: 15 * 60 * 1000, // 15 minutes
    sessionTimeout: null,
    appVersion: null,

    // Hooks
    init: function init() {
      this._super.apply(this, arguments);
      this._syncSessionExpiration();
      this.set('loginController', Ember.getOwner(this).lookup('controller:login'));
    },


    // Methods
    logout: function logout() {
      // Setting session data var to inform the 'sessionInvalidated' that the reason for invalidation is requested logout from user
      this.set('data.reasonForInvalidation', 'logout');
      return this.invalidate();
    },
    setTimeOfLastAPIActivity: function setTimeOfLastAPIActivity() {
      this.set('data.timeOfLastAPIActivity', new Date());
    },
    _syncSessionExpiration: function _syncSessionExpiration() {
      if (this.get('data.timeOfLastAPIActivity') && (0, _moment.default)().diff(this.get('data.timeOfLastAPIActivity'), 'milliseconds') > this.get('sessionDuration')) {
        this.set('data.reasonForInvalidation', 'inactivity');
        this.set('data.timeOfLastAPIActivity', false);

        if (this.get('isAuthenticated')) {
          this.invalidate();
        }
      }

      Ember.run.cancel(this.syncSessionExpirationTimeout);
      this.syncSessionExpirationTimeout = Ember.run.later(this, this._syncSessionExpiration, 500);
    },
    _alertCleanAndRedirect: function _alertCleanAndRedirect() {
      if (this.get('data.reasonForInvalidation') === 'logout') {
        this.get('alerting').notify("You've successfully logged out.", 'success', 'bottom-right-toast');
      } else if (this.get('data.reasonForInvalidation') === 'inactivity') {
        this.get('loginController').set('error', 'You were automatically logged out due to inactivity.');
      } else if (this.get('data.reasonForInvalidation') === 'no_privilege') {
        this.get('loginController').set('error', 'Your account does not have access to ZeroRisk PINpoint. If you think this is a mistake, please contact support.');
      } else {
        this.get('alerting').notify('Your session has expired. Please log back in.', 'warning', 'bottom-right-toast');
      }

      this.set('data.reasonForInvalidation', null);

      // Unloading all stores to clean last session data
      if (_environment.default['ember-cli-mirage'] && _environment.default['ember-cli-mirage'].enabled) {
        window.location.replace(_environment.default.rootURL);
      } else {
        this.get('store').unloadAll();
        this.get('routing').transitionTo('login');
      }
    },
    _populateCurrentUser: function _populateCurrentUser() {
      var _this = this;

      var _get = this.get('session.authenticated'),
          userId = _get.userId;

      return this.get('store').find('user', userId).then(function (user) {
        return _this.get('currentUser').set('content', user) && user;
      });
    },
    _checkPrivilege: function _checkPrivilege(user) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (user.get('privileges').includes('CAN_USE_PINPOINT')) {
          resolve(user);
        } else {
          _this2.set('data.reasonForInvalidation', 'no_privilege');
          _this2.invalidate();
          reject();
        }
      });
    },
    _forceTcAccept: function _forceTcAccept(user) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        if (!user.get('tcAccepted')) {
          _this3.get('routing').transitionTo('legal.terms-and-conditions');
          resolve(true);
        } else {
          resolve(false);
        }
      });
    },


    // CPs
    //appVersion: Ember.computed.alias('config.APP.version'),

    // Events
    beforeApplication: function beforeApplication(transition) {
      var _this4 = this;

      if (this.get('isAuthenticated')) {
        return this._populateCurrentUser().then(function (user) {
          return _this4._checkPrivilege(user);
        }).then(function (user) {
          return _this4._forceTcAccept(user);
        }).catch(function () {
          transition.abort();
        });
      }
    },
    afterAuthentication: function afterAuthentication() {
      var _this5 = this;

      this._populateCurrentUser().then(function (user) {
        return _this5._checkPrivilege(user);
      }).then(function (user) {
        return _this5._forceTcAccept(user);
      }).then(function (mustAcceptTc) {
        if (!mustAcceptTc) {
          if (_this5.get('attemptedTransition') && _this5.get('attemptedTransition').targetName !== "login") {
            _this5.get('attemptedTransition').retry();
            _this5.set('session.attemptedTransition', null);
          } else {
            if (_this5.get('currentUser.canUseDashboard')) {
              _this5.get('routing').transitionTo('dashboard');
            } else {
              _this5.get('routing').transitionTo(_environment.default['ember-simple-auth'].routeAfterAuthentication);
            }
          }
        }
      });
    },
    afterInvalidation: function afterInvalidation() {
      Ember.run.once(this, this._alertCleanAndRedirect);
    }
  });
});