define('pinpoint-manager/routes/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject'], function (exports, _authenticatedRouteMixin, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: (0, _inject.default)(),

    model: function model() {
      return this.get('ajax').request('/pinpoint/dashboard').then(function (payload) {
        return payload.result.dashboard;
      });
    }
  });
});