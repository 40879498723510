define('pinpoint-manager/components/location-entity-selector/info-block', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    icon: null,

    actions: {
      onClick: function onClick() {
        if (this.get('onClick')) {
          return this.get('onClick')();
        }
      }
    }
  });
});