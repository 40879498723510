define('pinpoint-manager/adapters/device-model', ['exports', 'pinpoint-manager/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v1/pinpoint/resources',

    query: function query(store, type, _query) {
      return this.ajax(this.host + '/' + this.namespace + '/models', 'POST', { data: _query });
    }
  });
});