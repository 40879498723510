define('pinpoint-manager/helpers/truncate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncate = truncate;
  function truncate(params, hash) {
    var value = params[0];
    var len = hash.limit;
    var out = '';

    if (value !== undefined) {
      out = value.substr(0, len);
      if (value.length > len) {
        out += '...';
      }
    } else {
      out = '';
    }

    return out;
  }

  exports.default = Ember.Helper.helper(truncate);
});