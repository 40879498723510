define('pinpoint-manager/serializers/location', ['exports', 'ember-data', 'pinpoint-manager/serializers/application'], function (exports, _emberData, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            address: { embedded: 'always' }
        },

        primaryKey: 'locationId',

        keyForRelationship: function keyForRelationship(key) {
            return key;
        },
        serialize: function serialize(snapshot, options) {
            var json = this._super(snapshot, options);

            delete snapshot._attributes.marker;
            delete json.markerIndex;
            delete json.marker;
            delete json.locationId;
            delete json.devicesCount;

            json.name = json.locationName;
            delete json.locationName;

            return json;
        }
    });
});