define('pinpoint-manager/authenticators/cookie', ['exports', 'ember-simple-auth/authenticators/base', 'pinpoint-manager/utils/paths'], function (exports, _base, _paths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authenticationRoute: 'login',

    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.userId)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(options) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ajaxSettings = options.token ? {
          type: 'GET',
          url: (0, _paths.default)().host() + '/' + (0, _paths.default)().namespace + '/users/sign_in?token=' + options.token
        } : {
          type: 'POST',
          contentType: 'application/json',
          url: (0, _paths.default)().host() + '/' + (0, _paths.default)().namespace + '/users/sign_in',
          data: JSON.stringify({
            username: options.identification,
            password: options.password
          })
        };

        Ember.$.ajax(ajaxSettings).done(function (response) {
          var result = void 0;
          if (typeof response === 'string') {
            result = JSON.parse(response).result;
          } else {
            result = response.result;
          }

          var resolved = {
            'userId': result.userId,
            'email': options.identification,
            'firstName': result.firstName,
            'lastName': result.lastName,
            'role': result.role,
            'token': result.token
          };

          Ember.run(function () {
            resolve(resolved);
          });
        }).fail(function (xhr) {
          Ember.run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },
    invalidate: function invalidate() {
      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        Ember.$.ajax({
          type: 'GET',
          url: (0, _paths.default)().host() + '/api/v1/users/sign_out'
        }).then(function (response) {
          Ember.run(function () {
            resolve(response);
          });
        }, function (xhr) {
          Ember.run(function () {
            resolve(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    }
  });
});