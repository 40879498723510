define('pinpoint-manager/models/cable', ['exports', 'ember-data/attr', 'ember-data-model-fragments'], function (exports, _attr, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    type: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string')
  });
});