define('pinpoint-manager/routes/audit/view/photos', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var photos = this.modelFor('audit.view').get('photos');

      var thereArePhotos = false;
      photos.forEach(function (photo) {
        // Initialize tabs and set photos in controller if there's at least one photo in the audit
        if (!Ember.isEmpty(photo.get('url'))) {
          thereArePhotos = true;
        }
      });
      if (thereArePhotos) {
        return photos;
      } else {
        return null;
      }
    },
    afterModel: function afterModel() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        setTimeout(function () {
          Ember.$('.audit-photos-tab').tabs();
          Ember.$('.materialboxed').materialbox();
        }, 200);
      });
    }
  });
});