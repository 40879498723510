define('pinpoint-manager/components/location-entity-input', ['exports', 'ember-service/inject'], function (exports, _inject) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		remodal: (0, _inject.default)(),
		store: (0, _inject.default)(),

		entities: Ember.computed(function () {
			return this.get('store').findAll('entity');
		}),

		actions: {
			openLocationEntitySelectorMOdal: function openLocationEntitySelectorMOdal() {
				this.get('remodal').open('location-entity-selector-modal');
			},
			closeLocationEntitySelectorModal: function closeLocationEntitySelectorModal() {
				this.get('remodal').close('location-entity-selector-modal');
			}
		}
	});
});