define('pinpoint-manager/models/quick-audit', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    device: _emberData.default.belongsTo('device'),
    userEmail: _emberData.default.attr('string'),
    userFirstname: _emberData.default.attr('string'),
    userLastname: _emberData.default.attr('string'),
    userFullName: Ember.computed('userFirstname', 'userLastname', function () {
      return this.get('userFirstname') + ' ' + this.get('userLastname');
    }),
    isSecure: _emberData.default.attr('boolean'),
    feedback: _emberData.default.attr('string'),
    date: _emberData.default.attr('date')
  });
});