define('pinpoint-manager/controllers/quick-audit', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: (0, _inject.default)(),

    justifications: Ember.A(['The device connectors are not securely fastened.', "Curls of the device cable aren't uniform.", 'A sticker is missing on the device.', 'There are clear signs of tampering.']),

    customFeedbackDidChange: Ember.observer('customFeedback', function () {
      if (this.get('customFeedback')) {
        this.set('chosenFeedback', this.get('customFeedback'));
      }
    }),

    actions: {
      getPhotosAndQuickAudits: function getPhotosAndQuickAudits() {
        var _this = this;

        this.set('photos', null);
        this.set('quickAudits', null);

        this.store.findRecord('audit', this.get('model.lastAuditId')).then(function (audit) {
          var thereArePhotos = false;
          var photos = audit.get('photos').forEach(function (photo) {
            if (!Ember.isEmpty(photo.get('url'))) {
              thereArePhotos = true;
            }
          });
          if (thereArePhotos) {
            _this.set('photos', photos);
          } else {
            _this.set('photos', null);
          }
        });

        this.store.query('quick-audit', { perPage: 10, page: 1, deviceId: this.get('model.id') }).then(function (quickAudits) {
          _this.set('quickAudits', quickAudits);

          Ember.run.schedule('afterRender', _this, function () {
            Ember.$('.tooltipped').tooltip({ delay: 0 });
          });
        });
      },
      chooseToReport: function chooseToReport(choseToReport) {
        if (choseToReport) {
          this.setProperties({
            'isSecure': false,
            'mustProvideFeedback': true,
            'okToSubmit': 'Submit'
          });
        } else {
          this.set('isSecure', true);
          this.send('sendQuickAudit');
        }
      },
      chooseFeedback: function chooseFeedback(justification) {
        this.set('chosenFeedback', justification);
        this.set('customFeedback', null);
      },
      sendQuickAudit: function sendQuickAudit() {
        var _this2 = this;

        var data = { 'quickAudit': { device: this.get('model.id'), isSecure: this.get('isSecure') } };
        if (!this.get('isSecure')) {
          data.quickAudit.feedback = this.get('chosenFeedback');
        }

        this.get('ajax').post('pinpoint/quickaudit', {
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function () {
          _this2.set('completed', true);
          _this2.get('model').reload();
        }).catch(function (response) {
          _this2.get('errorParser').parseAndDisplay(response, 'notification');
        });
      },
      showPhoto: function showPhoto(photoUrl) {
        this.setProperties({
          'showingPhoto': true,
          'showedPhotoUrl': photoUrl
        });
      },
      exitShowPhoto: function exitShowPhoto() {
        this.setProperties({
          'showingPhoto': false,
          'showedPhotoUrl': null
        });
      },
      close: function close() {
        this.setProperties({
          mustProvideFeedback: false,
          chosenFeedback: null,
          completed: false,
          isSecure: null,
          okToSubmit: false,
          customFeedback: null,
          showingPhoto: false,
          showedPhoto: null
        });
        this.send('removeModal');
      }
    }
  });
});