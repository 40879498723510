define('pinpoint-manager/controllers/admin/users', ['exports', 'ember-cli-pagination/computed/paged-array'], function (exports, _pagedArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    searchQuery: null,

    queryParams: ['location', 'page', 'perPage'],

    filteredUsers: computed('model', 'searchQuery', function () {
      var model = this.get('model');
      var searchQuery = this.get('searchQuery');

      this.set('page', 1);

      if (searchQuery) {
        return model.filter(function (user) {
          return user.get('email') && user.get('email').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || user.get('fisrtName') && user.get('fisrtName').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 || user.get('lastName') && user.get('lastName').toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
      } else {
        return model;
      }
    }),

    page: 1,
    perPage: 10,
    pagedAndFilteredUsers: (0, _pagedArray.default)('filteredUsers', { page: Ember.computed.alias("parent.page"), perPage: Ember.computed.alias("parent.perPage") }),
    totalPages: Ember.computed.oneWay("pagedAndFilteredUsers.totalPages")
  });
});