define('pinpoint-manager/models/compliance-report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var inflector = Ember.Inflector.inflector;
    inflector.irregular('compliance', 'compliance');
    inflector.uncountable('compliance');

    exports.default = _emberData.default.Model.extend({
        isLocationSet: _emberData.default.attr('boolean'),
        isAuditCompleted: _emberData.default.attr('boolean'),
        isCustomDevice: _emberData.default.attr('boolean'),
        photoCoverage: _emberData.default.attr('number'),
        isSurveyCompliant: _emberData.default.attr('boolean'),
        isDeviceExpired: _emberData.default.attr('boolean'),
        isDeviceCompliant: _emberData.default.attr('boolean'),
        isAuditExpired: _emberData.default.attr('boolean')
    });
});