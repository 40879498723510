define('pinpoint-manager/components/rate-cards', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$('.rate-card').velocity('transition.slideRightIn', { stagger: 300 });
    }
  });
});