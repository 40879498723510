define('pinpoint-manager/controllers/user/recover/request', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: (0, _inject.default)(),

    actions: {
      sendResetRequest: function sendResetRequest() {
        var _this = this;

        var email = this.get('email');

        if (Ember.isEmpty(email)) {
          this.set('error', 'No email provided. Please try again.');
        } else {
          var ns = this.get('ajax.namespace');
          this.set('ajax.namespace', '/api/v2/');
          this.get('ajax').post('shared/users/forgot', {
            data: JSON.stringify({ email: email }),
            contentType: 'application/json'
          }).then(function () {
            _this.set('requested', true);
          }).catch(function (response) {
            _this.set('error', _this.get('errorParser').parseAndDisplay(response, 'box'));
          }).finally(function () {
            return _this.set('ajax.namespace', ns);
          });
        }
      }
    }
  });
});