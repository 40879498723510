define('pinpoint-manager/models/custom-field', ['exports', 'ember-data/attr', 'ember-data-model-fragments/fragment'], function (exports, _attr, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('string')
  });
});