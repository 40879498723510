define('pinpoint-manager/controllers/user/recover/reset', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: (0, _inject.default)(),

    actions: {
      sendNewPassword: function sendNewPassword() {
        var _this = this;

        var token = this.get('token'),
            password = this.get('password'),
            passwordRepeat = this.get('passwordRepeat');

        if (Ember.isEmpty(token)) {
          this.set('error', 'No token provided. Please try again.');
        } else {
          var ns = this.get('ajax.namespace');
          this.set('ajax.namespace', '/api/v2/');
          this.get('ajax').post('shared/users/forgot/' + token, {
            contentType: 'application/json',
            data: JSON.stringify({ password: password, passwordRepeat: passwordRepeat })
          }).then(function () {
            _this.set('reset', true);
            Ember.run.later(_this, function () {
              _this.transitionToRoute('login');
            }, 4000);
          }).catch(function (response) {
            _this.set('error', _this.get('errorParser').parseAndDisplay(response, 'box'));
          }).finally(function () {
            return _this.set('ajax.namespace', ns);
          });
        }
      }
    }
  });
});