define('pinpoint-manager/serializers/survey-answer', ['exports', 'pinpoint-manager/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    //primaryKey: 'questionId',

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      delete json.auditId;
      delete json.question;
      return json;
    }
  });
});