define('pinpoint-manager/serializers/audit', ['exports', 'pinpoint-manager/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      surveyAnswers: { embedded: 'always' },
      photos: { embedded: 'always' }
    },
    keyForRelationship: function keyForRelationship(key) {
      return key;
    }
  }
  // serialize(snapshot, options) {
  //   var json = this._super(snapshot, options);
  //   delete json.device;
  //   return json;
  // }
  );
});