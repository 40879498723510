define('pinpoint-manager/components/modals/disable-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  var alias = computed.alias;
  exports.default = Ember.Component.extend({
    user: alias('model'),
    remodal: service(),
    errorParser: service(),

    actions: {
      confirm: function confirm() {
        var _this = this;

        var user = this.get('model');
        user.set('enabled', false);
        user.save().then().catch(function (response) {
          user.rollbackAttributes();
          _this.get('errorParser').parseAndDisplay(response, 'notification');
        });
        this.send('closeModal');
      },
      closeModal: function closeModal() {
        this.get('remodal').close('disable-user');
      }
    }
  });
});