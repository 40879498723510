define('pinpoint-manager/serializers/manufacturer', ['exports', 'pinpoint-manager/serializers/application'], function (exports, _application) {
      'use strict';

      Object.defineProperty(exports, "__esModule", {
            value: true
      });
      exports.default = _application.default.extend({
            normalizeArrayResponse: function normalizeArrayResponse(store, type, payload) {
                  var manufacturers = payload.manufacturers;

                  var generatedId = 0;
                  manufacturers.forEach(function (manufacturer) {
                        manufacturer.id = generatedId;
                        generatedId++;
                  });

                  payload = { manufacturers: manufacturers };

                  return this._super(store, type, payload);
            }
      });
});