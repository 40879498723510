define('pinpoint-manager/controllers/audit/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var $ = Ember.$;

  exports.default = Ember.Controller.extend({

    // activeSection: 'compliance',
    // activeSectionTemplate: function() {
    //   return 'audit/sections/' + this.get('activeSection');
    // }.property('activeSection'), 

    // changeSection(section) {
    // 	this.set('activeSection', section);
    // 	this.send('setPhotosTabsAndMaterialbox');
    // },

    photosDidChange: Ember.observer('photos', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setPhotosTabsAndMaterialbox');
    }),

    setPhotosTabsAndMaterialbox: function setPhotosTabsAndMaterialbox() {
      setTimeout(function () {
        $('ul.tabs').tabs();
        $('.materialboxed').materialbox();
      }, 200);
    }
  });
});