define('pinpoint-manager/adapters/user', ['exports', 'pinpoint-manager/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api/v2/shared',

    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return this._buildURL(modelName) + '?notifyUser=' + snapshot.adapterOptions.notify;
    }
  });
});