define('pinpoint-manager/components/users-manager', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: (0, _inject.default)(),
    errorParser: (0, _inject.default)(),
    remodal: (0, _inject.default)(),

    selectedUser: null,

    currentUsers: Ember.computed.filterBy('users', 'isNew', false),

    actions: {
      openDisableUserModal: function openDisableUserModal(user) {
        this.set('selectedUser', user);
        this.get('remodal').open('disable-user');
      },
      openEnableUserModal: function openEnableUserModal(user) {
        this.set('selectedUser', user);
        this.get('remodal').open('enable-user');
      },
      openAddEditUserModal: function openAddEditUserModal(user, mode) {
        this.set('selectedUser', user);
        this.set('addEditUserModalMode', mode);
        this.get('remodal').open('add-edit-user');
      },
      reloadUsers: function reloadUsers() {
        this.get('users').update();
      }
    }
  });
});