define('pinpoint-manager/routes/audit/view/compliance', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject', 'pinpoint-manager/utils/paths'], function (exports, _authenticatedRouteMixin, _inject, _paths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    files: (0, _inject.default)(),

    activate: function activate() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('.collapsible').collapsible({ accordion: false });
      });
    },
    model: function model() {
      // get the compliance-report for the passed audit's device id    
      return this.store.findRecord('compliance-report', this.modelFor('audit.view').belongsTo("device").id(), { reload: true });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.modelFor('audit.view').get('device').then(function (device) {
        return controller.set('device', device);
      });
    },


    actions: {
      downloadReport: function downloadReport() {
        this.get('files').download('api/v2/shared/public/download/pinpoint/audit', 'auditId=' + this.modelFor('audit.view').get('id') + '&deviceId=' + this.get('controller.device.id'));
      }
    }
  });
});