define('pinpoint-manager/controllers/audit/new', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: (0, _inject.default)(),

    isSubmitting: false,

    cableColors: [{ text: 'Red', value: 'RED' }, { text: 'Blue', value: 'BLUE' }, { text: 'Yellow', value: 'YELLOW' }, { text: 'Black', value: 'BLACK' }, { text: 'White', value: 'WHITE' }, { text: 'Green', value: 'GREEN' }, { text: 'Brown', value: 'BROWN' }, { text: 'Purple', value: 'PURPLE' }],

    cableTypes: [{ text: 'Curly', value: 'CURLY' }, { text: 'Straight', value: 'STRAIGHT' }, { text: 'Partial', value: 'PARTIAL' }],

    cablesAmount: 0,

    photoTypes: Ember.A([Ember.Object.create({ name: 'front', isUploading: false, isUploaded: false }), Ember.Object.create({ name: 'back', isUploading: false, isUploaded: false }), Ember.Object.create({ name: 'top', isUploading: false, isUploaded: false }), Ember.Object.create({ name: 'bottom', isUploading: false, isUploaded: false }), Ember.Object.create({ name: 'left', isUploading: false, isUploaded: false }), Ember.Object.create({ name: 'right', isUploading: false, isUploaded: false })]),

    //_inspectionIsComplete: Ember.computed.and('model.cableType', 'model.software'),
    inspectionIsComplete: Ember.computed.bool('model.software'),

    photosIsComplete: Ember.computed.and('photoUploaded'),

    surveyIsComplete: Ember.computed('model.surveyAnswers.[]', 'surveyQuestions', function () {
      if (this.get('surveyQuestions')) {
        return this.get('model').get('surveyAnswers').mapBy('answer').length === this.get('surveyQuestions').length;
      } else {
        return false;
      }
    }),

    auditIsComplete: Ember.computed.and('inspectionIsComplete', 'photosIsComplete', 'surveyIsComplete'),
    auditIsNotComplete: Ember.computed.not('auditIsComplete'),

    onSurveyQuestion5StatusChange: Ember.observer('willAnswerSurveyQuestion5', 'surveyQuestion5Answer', function () {
      // If use hasn't checked to report tampering evidence, set the answer to 0
      if (!this.get('willAnswerSurveyQuestion5')) {
        this.send('answerSurveyQuestion', { id: 5, answer: 0 });
        // Otherwise if not and he wrote something in the evidence reporting checkbox, fill the answer with it svalue
      } else if (!Ember.isEmpty(this.get('surveyQuestion5Answer'))) {
        this.send('answerSurveyQuestion', { id: 5, answer: this.get('surveyQuestion5Answer') });
      }
    }),

    actions: {
      photoLoaded: function photoLoaded(type, file) {
        var _this = this;

        this.get('photoTypes').findBy('name', type).setProperties({
          'isUploading': true,
          'isUploaded': false
        });

        // this.get('imageTools').resize(file, { width: 1200, height: 800 }, (resizedBlob, resizeOk) => {            
        var formData = new FormData();
        formData.append('deviceId', this.model.belongsTo("device").id());
        formData.append('type', type);
        // if(resizeOk) {
        //   resizedBlob.lastModifiedDate = new Date();
        //   resizedBlob.name = file.name;   
        //   formData.append('file', resizedBlob);           
        // } else {
        formData.append('file', file);
        // }

        this.get('ajax').post('pinpoint/photos', {
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).then(function (response) {
          _this.set('photoUploaded', true);
          _this.get('photoTypes').findBy('name', type).setProperties({
            'isUploading': false,
            'isUploaded': true
          });

          var photo = _this.store.createFragment('photo', response.result.photo);
          _this.get('model.photos').pushObject(photo);
        }).catch(function (response) {
          _this.get('errorParser').parseAndDisplay(response.responseJSON, 'notification');
          _this.get('photoTypes').findBy('name', type).set('isUploading', false);
        });
        // });
      },
      setCableType: function setCableType(type) {
        this.set('model.cableType', type);
      },
      setCables: function setCables(amount) {
        this.get('model.cables').forEach(function (cable) {
          if (cable) {
            cable.destroy();
          }
        });

        this.get('model.cables').rollbackAttributes();

        for (var i = 0; i < amount; i++) {
          var cable = this.store.createFragment('cable', { type: null, color: null, notes: null });
          this.get('model.cables').pushObject(cable);
        }
      },
      answerSurveyQuestion: function answerSurveyQuestion(hash) {
        var surveyAnswer = this.get('model.surveyAnswers').findBy('questionId', hash.id);
        if (surveyAnswer) {
          surveyAnswer.set('answer', hash.answer);
        } else {
          var _surveyAnswer = this.store.createFragment('surveyAnswer', { questionId: hash.id, answer: hash.answer });
          this.get('model.surveyAnswers').pushObject(_surveyAnswer);
        }
      },
      submitAudit: function submitAudit() {
        var _this2 = this;

        this.set('isSubmitting', true);
        this.get('model').save().then(function () {
          Materialize.toast('Audit successfully completed.', 5000);
          window.history.go(-1);
          _this2.set('isSubmitting', false);
        }).catch(function (response) {
          _this2.get('errorParser').parseAndDisplay(response, 'notification');
          _this2.set('isSubmitting', false);
        });
      }
    }
  });
});