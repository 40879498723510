define('pinpoint-manager/components/modals/edit-current-user', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    errorParser: (0, _inject.default)(),
    remodal: (0, _inject.default)(),
    session: (0, _inject.default)(),
    routing: (0, _inject.default)('-routing'),

    user: null,

    mode: null,
    errors: null,

    actions: {
      saveUser: function saveUser() {
        var _this = this;

        this.get('user.content').save().then(function () {
          _this.setProperties({
            'completed': true,
            'errors': null
          });
        }).catch(function (response) {
          _this.set('errors', _this.get('errorParser').parseAndDisplay(response, 'box'));
        });
      },
      reset: function reset() {
        this.setProperties({
          // 'user': null,
          'errors': null,
          'completed': false
        });

        this.get('user.content').rollbackAttributes();
      },
      closeModal: function closeModal() {
        this.get('remodal').close('edit-current-user');
      },
      startChangePassword: function startChangePassword() {
        var _this2 = this;

        this.send('closeModal');
        this.get('session').logout().then(function () {
          _this2.get('routing').transitionTo('user.recover.request');
        });
      }
    }
  });
});