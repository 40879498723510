define('pinpoint-manager/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'pinpoint-manager/language'], function (exports, _model, _attr, _relationships, _language) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    numericId: computed('id', function () {
      return Number(this.get('id'));
    }),
    entities: (0, _relationships.hasMany)('entity'),
    email: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    tcAccepted: (0, _attr.default)('boolean'),
    roles: (0, _relationships.hasMany)('roles'),
    privileges: (0, _attr.default)(),
    enabled: (0, _attr.default)('boolean'),
    isSeamless: computed('seamlessToken', function () {
      return this.get('seamlessToken') === null;
    }),
    fullName: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    // humanRoles: computed('roles', function () {
    //   const roles = this.get('roles');
    //   if (roles)
    //     return roles.any(role => Language.roles[role]);
    // }),

    // Roles
    isAdmin: computed('roles', function () {
      return this.get('roles').isAny('roleType', 'ROLE_PINPOINT_ADMIN') || this.get('roles').isAny('roleType', 'ROLE_ADMIN');
    }),
    isZoneManager: computed('roles', function () {
      return this.get('roles').isAny('roleType', 'ROLE_PINPOINT_MANAGER_ZONE');
    }),
    isAreaManager: computed('roles', function () {
      return this.get('roles').isAny('roleType', 'ROLE_PINPOINT_MANAGER_AREA');
    }),
    isLocationManager: computed('roles', function () {
      return this.get('roles').isAny('roleType', 'ROLE_PINPOINT_MANAGER_LOCATION');
    }),
    isAuditor: computed('roles', function () {
      return this.get('roles').isAny('roleType', 'ROLE_PINPOINT_AUDITOR');
    }),

    // Permissions
    canUseDashboard: computed('roles', function () {
      return this.get('isAdmin') || this.get('isZoneManager');
    }),

    canAccessAllLocationsInDevices: computed('roles', function () {
      return this.get('isAuditor');
    }),

    canUseLocations: computed('roles', function () {
      return !this.get('isAuditor');
    }),
    canUseUsersManagement: computed('roles', function () {
      return !this.get('isAuditor');
    }),

    canCreateZones: computed('roles', function () {
      return this.get('isAdmin');
    }),
    canCreateAreas: computed('roles', function () {
      return this.get('isAdmin') || this.get('isZoneManager');
    }),
    canCreateLocations: computed('roles', function () {
      return this.get('isAdmin') || this.get('isZoneManager') || this.get('isAreaManager');
    }),

    canCreateDevices: computed('roles', function () {
      return !this.get('isAuditor');
    }),
    canEditDevices: computed('roles', function () {
      return !this.get('isAuditor');
    }),

    canDownloadDevicesTableReport: computed('roles', function () {
      return this.get('isAdmin') || this.get('isZoneManager') || this.get('isAreaManager');
    }),
    canDownloadComplianceReport: computed('roles', function () {
      return this.get('isAdmin') || this.get('isZoneManager') || this.get('isAreaManager') || this.get('isLocationManager');
    })
  });
});