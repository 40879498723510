define('pinpoint-manager/adapters/compliance-report', ['exports', 'pinpoint-manager/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // namespace: 'api/v1/pinpoint/compliance',

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.host + '/' + this.namespace + '/compliance/' + id, 'GET');
    }
  });
});