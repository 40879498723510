define('pinpoint-manager/components/device-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        _this.$('.dropdown-button').dropdown();
      });
    },


    actions: {
      openModal: function openModal(name, model) {
        this.sendAction('openModal', name, model);
      }
    }
  });
});