define('pinpoint-manager/routes/audit/view', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('audit', params.audit_id, { reload: true });
    }
  });
});