define('pinpoint-manager/serializers/contact-info', ['exports', 'pinpoint-manager/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			address: { embedded: 'always' }
		},

		keyForRelationship: function keyForRelationship(key) {
			return key;
		}
	});
});