define('pinpoint-manager/services/files', ['exports', 'ember-service/inject', 'pinpoint-manager/config/environment', 'pinpoint-manager/utils/paths'], function (exports, _inject, _environment, _paths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;


  var $ = Ember.$;

  exports.default = Ember.Service.extend({
    ajax: (0, _inject.default)(),
    session: (0, _inject.default)(),
    alerting: (0, _inject.default)(),

    download: function download(url) {
      var _this = this;

      var extraParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var baseUrlAndUrl = _environment.default.environment === 'development' ? 'http://pinpoint.zerorisk.local:8080/' + url : (0, _paths.default)().host() + '/' + url;
      this.set('ajax.namespace', '/api/v2/');
      return this.get('ajax').request('shared/download/token').then(function (payload) {
        var finalUrl = baseUrlAndUrl + '?token=' + payload.result.token;
        if (extraParams) finalUrl += '&' + extraParams;

        return new Ember.RSVP.Promise(function (resolve) {
          return $.fileDownload(finalUrl).done(function () {
            return resolve();
          });
        });
        // window.location.assign(`${baseUrlAndUrl}?token=${payload.result.token}`);
      }).finally(function () {
        return _this.set('ajax.namespace', '/api/v1/');
      });
    },
    upload: function upload(_ref) {
      var _this2 = this;

      var url = _ref.url,
          file = _ref.file,
          _ref$fileData = _ref.fileData,
          fileData = _ref$fileData === undefined ? null : _ref$fileData;

      var authToken = this.get('session.data.authenticated.token');
      var uploaderOptions = {
        headers: {
          "X-AUTH-TOKEN": authToken
        }
      };

      if (fileData) {
        uploaderOptions.data = fileData;
      }
      return new RSVP.Promise(function (resolve, reject) {
        file.upload(url, uploaderOptions).then(function (_ref2) {
          var result = _ref2.body.result;

          resolve(result);
        }).catch(function (_ref3) {
          var errors = _ref3.body.errors;

          _this2.cancelUploaderQueue(file);
          errors.forEach(function (_ref4) {
            var detail = _ref4.detail;

            _this2.get('alerting').notify(detail, 'error');
          });
          reject(errors);
        });
      });
    },
    cancelUploaderQueue: function cancelUploaderQueue(file) {
      file.queue.remove(file);
    }
  });
});