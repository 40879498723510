define('pinpoint-manager/components/material-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.sendAction('onOpen');
      this.$('.modal').openModal({
        complete: function complete() {
          _this.sendAction('close');
        }
      });
    },


    actions: {
      ok: function ok() {
        this.sendAction('ok');
      },
      close: function close() {
        Ember.$('.modal').closeModal();
        this.sendAction('close');
      }
    }
  });
});