define('pinpoint-manager/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    keyForRelationship: function keyForRelationship(key) {
      return key + 'Id';
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // Every model content is in the result object of the payload
      return this._super(store, primaryModelClass, payload.result, id, requestType);
    }
  });
});