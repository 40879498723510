define('pinpoint-manager/components/validated-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var $ = Ember.$;

  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      Ember.$('select').material_select();
    },


    actions: {
      valueDidChange: function valueDidChange() {
        this.set('value', $("select#" + this.get('cid')).val());
        // If there is a label, it means that the 'prompt' text is used as placeholder, so we have to make the selected text black after the user change it from the prompt text default value
        if (this.get('label')) {
          Ember.$("select#" + this.get('cid')).prev().prev().addClass('grey-text text-darken-3');
        }
      }
    }

  });
});