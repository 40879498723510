define('pinpoint-manager/routes/audit/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-service/inject'], function (exports, _authenticatedRouteMixin, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: (0, _inject.default)(),

    model: function model() {
      return this.store.createRecord('audit');
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      // Call _super for default behavior
      this._super(controller, model);
      // Set audit's device
      this.store.findRecord('device', this.paramsFor('audit.new').device_id).then(function (device) {
        model.set('device', device);
      });
      // Get all survey questions
      this.get('ajax').request('pinpoint/survey').then(function (response) {
        controller.set('surveyQuestions', response.result.survey.connections);
        // Set survey answer 5 "Check this to report tampering evidences.." by default to 0		
        var surveyAnswer = _this.store.createFragment('surveyAnswer', { questionId: 5, answer: 0 });
        model.get('surveyAnswers').pushObject(surveyAnswer);
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'errorMessage': null,
          'photoUploaded': false,
          'willAnswerSurveyQuestion5': false,
          'surveyQuestion5Answer': null,
          'isSubmitting': false
        });
        controller.get('photoTypes').forEach(function (photoType) {
          return photoType.setProperties({
            'isUploading': false,
            'isUploaded': false
          });
        });
        this.currentModel.unloadRecord();
        this.store.unloadAll('surveyAnswer');
      }
    }
  });
});