define('pinpoint-manager/services/alerting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // Methods
    notify: function notify(message) {
      Ember.run.scheduleOnce('afterRender', function () {
        Materialize.toast(message.replace(/<\/?[^>]+(>|$)/g, ""), 4000);
      });
    }
  });
});