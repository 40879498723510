define('pinpoint-manager/services/navi', ['exports', 'pinpoint-manager/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner,
      computed = Ember.computed,
      observer = Ember.observer;
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    history: [],

    init: function init() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);
      this.set('applicationController', getOwner(this).lookup('controller:application'));
      if (_environment.default.APP.version) {
        this.set('appVersion', _environment.default.APP.version);
      }
    },


    currentRouteName: computed.alias('applicationController.currentRouteName'),

    isInIndexRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName') === 'index';
    }),
    isInDevicesIndexRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName') === 'devices.index';
    }),
    isInLocationsRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName') === 'locations';
    }),
    isInAdminUsersRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName') === 'admin.users';
    }),

    hasHistory: computed('history.length', function () {
      return this.get('history.length') > 1;
    }),

    watchHistory: observer('currentPath', function () {
      this.get('history').pushObject(this.get('currentPath'));
    }),

    goHome: function goHome() {
      if (this.get('session.isAuthenticated')) {
        this.get('routing').transitionTo('devices.index');
      } else {
        this.get('routing').transitionTo('index');
      }
    }
    // actions: {
    // 	goBack: function(){
    // 	 // Temporarly reverted to going to /devices/all
    // 	 // TODO: implement going back to /devices/all only if in tabbed routes (like audit.view) to prevent going back thousand times if user visited a lot of tabs
    // 	 this.transitionTo('devices.index', 'all');
    // 	 //    if(this.get('hasHistory')){
    // 	 //      this.get('history').popObject();
    // 	 //      window.history.back();
    // 	 //      this.get('history').popObject();
    // 		// }
    // 	}
    // }

  });
});