define('pinpoint-manager/components/pp-main', ['exports', 'ember-service/inject'], function (exports, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['navi.isInAdminUsersRoute:white'],
    tagName: 'main',
    navi: (0, _inject.default)()
  });
});