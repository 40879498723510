define('pinpoint-manager/models/device', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed('id', function () {
      return Number(this.get('id'));
    }),
    audit: _emberData.default.belongsTo('audit', { async: true }),
    entity: _emberData.default.belongsTo('entity', { async: true }), // retrieved on demand when /entities is GET
    currentStatus: _emberData.default.attr('string'),
    serial: _emberData.default.attr('string'),
    manufacturer: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    tag: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    customFields: (0, _attributes.fragmentArray)('custom-field'),
    lastAuditId: _emberData.default.attr('number'),
    lastAuditDate: _emberData.default.attr('date'),
    isApprovedDevice: _emberData.default.attr('boolean'),
    approvedDeviceId: _emberData.default.attr('number'),
    customDeviceId: _emberData.default.attr('number'),
    isDeviceCompliant: _emberData.default.attr('boolean'),
    isSecure: _emberData.default.attr('boolean'), // This attr changes based on the device's quickaudit outcome
    isNotSecure: Ember.computed.not('isSecure'),
    isEnabled: Ember.computed('currentStatus', function () {
      return this.get('currentStatus') == "IN_STOCK" || this.get('currentStatus') == "INSTALLED";
    }),
    quickAudits: _emberData.default.hasMany('quick-audit', { async: true })
  });
});