define('pinpoint-manager/models/contact-info', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    companyName: [(0, _emberCpValidations.validator)('length', { min: 3, max: 30 })],
    contactName: [(0, _emberCpValidations.validator)('length', { min: 3, max: 30 })],
    title: [(0, _emberCpValidations.validator)('length', { min: 1, max: 30 })],
    phone: [(0, _emberCpValidations.validator)('length', { min: 5, max: 20 })],
    'address': (0, _emberCpValidations.validator)('belongs-to')
  });

  exports.default = _model.default.extend(Validations, {
    email: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string'),
    contactName: (0, _attr.default)('string'),
    dba: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    fax: (0, _attr.default)('string'),
    address: (0, _relationships.belongsTo)('address') // embedded
  });
});