define('pinpoint-manager/services/ajax', ['exports', 'ember-ajax/services/ajax', 'ember-service/inject', 'ember-ajax/errors', 'pinpoint-manager/utils/paths'], function (exports, _ajax, _inject, _errors, _paths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberError = Ember.Error;
  exports.default = _ajax.default.extend({
    session: (0, _inject.default)(),
    router: (0, _inject.default)(),

    host: (0, _paths.default)().host(),
    namespace: '' + (0, _paths.default)().namespace,

    headers: Ember.computed(function () {
      var authToken = this.get('session.data.authenticated.token'),
          csrfCookie = document.cookie.match(/X-\CSRF\-TOKEN\=([^;]*)/),
          headers = {};

      if (authToken) {
        headers["X-AUTH-TOKEN"] = authToken;
      }

      if (csrfCookie) {
        headers["X-CSRF-TOKEN"] = decodeURIComponent(Ember.get(csrfCookie, "1"));
      }

      headers.App = "pinpoint";

      return headers;
    }).volatile(),

    // isSuccess(status, headers, payload) {
    //   return payload.success;
    // }

    handleResponse: function handleResponse() {
      var handledResponse = this._super.apply(this, arguments);

      if ((0, _errors.isForbiddenError)(handledResponse)) {
        Ember.Logger.debug("Not authorized to perform this operation.");
        // temporarily disabled
        //this.get('session').invalidate();
      }

      if ((0, _errors.isUnauthorizedError)(handledResponse) && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }

      if ((0, _errors.isNotFoundError)(handledResponse) || (0, _errors.isServerError)(handledResponse)) {
        this.get('router').transitionTo('error');
      }

      if (!(handledResponse instanceof EmberError)) {
        this.get('session').setTimeOfLastAPIActivity();
      }

      return handledResponse;
    }
  });
});