define('pinpoint-manager/models/entity', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    'contactInfo': (0, _emberCpValidations.validator)('belongs-to'),
    'address': (0, _emberCpValidations.validator)('belongs-to')
  });

  exports.default = _model.default.extend(Validations, {
    users: (0, _relationships.hasMany)('user', { async: true }), // retrieved on demand when /users is GET
    children: (0, _relationships.hasMany)('entity', { inverse: 'parent', async: true }),
    parent: (0, _relationships.belongsTo)('entity', { inverse: 'children', async: true }),
    contactInfo: (0, _relationships.belongsTo)('contact-info', { async: false }), // embedded
    locationType: (0, _attr.default)('string'),
    humanLocationType: Ember.computed('locationType', function () {
      return this.get('locationType') ? Ember.String.capitalize(this.get('locationType').toLowerCase()) : null;
    }),
    isTopZone: computed('locationType', function () {
      return this.get('locationType') === 'TOP_ZONE';
    }),
    isZone: computed('locationType', function () {
      return this.get('locationType') === 'ZONE';
    }),
    isArea: computed('locationType', function () {
      return this.get('locationType') === 'AREA';
    }),
    isLocation: computed('locationType', function () {
      return this.get('locationType') === 'LOCATION';
    }),
    merchant: (0, _attr.default)() // nested and readOnly
  });
});