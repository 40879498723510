define('pinpoint-manager/services/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = Ember.Service.extend({
    navi: Ember.inject.service(),

    init: function init() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);
      this.set('locationsController', getOwner(this).lookup('controller:locations'));
      this.set('devicesIndexController', getOwner(this).lookup('controller:devices/index'));
      this.set('adminUsersController', getOwner(this).lookup('controller:admin/users'));
    },


    devicesSearchResultsLenght: 0,

    clearSearchQuery: function clearSearchQuery() {
      if (this.get('navi.isInDevicesIndexRoute')) {
        this.set('devicesIndexController.searchQuery', null);
      } else if (this.get('navi.isInLocationsRoute')) {
        this.set('locationsController.searchQuery', null);
      } else if (this.get('navi.isInAdminUsersRoute')) {
        this.set('adminUsersController.searchQuery', null);
      }
    }
  });
});