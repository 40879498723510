define('pinpoint-manager/serializers/device', ['exports', 'pinpoint-manager/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      delete json.isApprovedDevice;
      delete json.isDeviceCompliant;
      delete json.positionPhoto;
      delete json.lastAuditId;
      delete json.auditId;
      delete json.lastAuditDate;
      delete json.isSecure;
      delete json.numericId;

      json.customFields = JSON.stringify(json.customFields);

      // <test>
      if (json.locationId) {
        json.locationName = null;
      }

      if (json.customDeviceId || json.approvedDeviceId) {
        json.manufacturer = null;
        json.model = null;
      }

      return json;
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, type, payload) {
      if (payload.device.customFields) {
        payload.device.customFields = JSON.parse(payload.device.customFields);
      }
      return this._super(store, type, payload);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, type, payload) {
      payload.devices.forEach(function (device) {
        if (device.customFields) {
          device.customFields = JSON.parse(device.customFields);
        }
      });
      return this._super(store, type, payload);
    }
  });
});